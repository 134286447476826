import React from 'react';
import { Row, Col, Badge } from 'antd';
import { Text, TextTypes, IconButton } from 'spoton-lib';
import { IGiftCard, IReduxStore } from '../../../common/types';
import { STATUS } from '../../../common/constants';
import { connect } from 'react-redux';
import Utils from '../../../common/utils';
import DateUtils from '../../../common/date-utils';
import './bag-item.scss';

interface IBagItemProps {
    card: IGiftCard;
    editable: boolean;
    handleEdit: (card: IGiftCard) => void;
    handleRemove: (card: IGiftCard) => void;
}

function BagItem({ card, ...props }: IBagItemProps) {
    let { senderInfo, recipientInfo } = card;
    let sessionInfo = Utils.getSenderInfo();
    let customDate = DateUtils.getCustomDate(card.customDate);
    let customTime = DateUtils.getCustomTime(card.customTime);

    const renderCardInfo = () => (
        <Col className="cardInfo" span={18}>
            <Text type={TextTypes.P} className="name">
                To:&nbsp;
                {senderInfo.shipToSelf === 'Y'
                    ? sessionInfo.senderName
                    : recipientInfo.recipientName}
            </Text>
            <Text type={TextTypes.P} className="email">
                {senderInfo.shipToSelf === 'Y'
                    ? 'Email Delivered to You'
                    : recipientInfo.recipientEmail}
            </Text>
            <Text type={TextTypes.P} className="date">
                Send: {customDate + ' | ' + customTime}
            </Text>
        </Col>
    );

    let images = card.cardImageUrl?.split(';').slice(0, 3) || [];
    let badgeOffset = '60%';
    if (images.length > 1) {
        badgeOffset = images.length === 2 ? '70%' : '85%';
    }

    return (
        <div className="bagItemMobile dNone dsmBlock">
            <Row>
                <Col span={6}>
                    <Badge count={card.quantity} showZero={false} offset={[-5, badgeOffset]}>
                        {images.map((url, i) => (
                            <img
                                key={i}
                                src={url + Utils.getRandomString()}
                                className="cardImage"
                                alt="card design"
                            />
                        ))}
                    </Badge>
                    <Text className="amount" type={TextTypes.P}>
                        {'$' + parseFloat(card.denomination).toFixed(2)}
                    </Text>
                </Col>
                {renderCardInfo()}
            </Row>
            {props.editable && (
                <div className="icons dFlex">
                    <IconButton
                        size={24}
                        alt="edit icon"
                        name="EditIcon"
                        color="#1769FF"
                        className="editIcon"
                        onClick={() => props.handleEdit(card)}
                        buttonProps={{
                            'aria-label': 'Edit card',
                        }}
                    />
                    {!Utils.isPromoCard(card.card) ? (
                        <IconButton
                            size={24}
                            alt="delete icon"
                            name="DeleteIcon"
                            color="#F73E3C"
                            className="deleteIcon"
                            onClick={() => props.handleRemove(card)}
                            buttonProps={{
                                'aria-label': 'Remove card',
                            }}
                        />
                    ) : (
                        <div style={{ width: 40, display: 'inline-block' }} />
                    )}
                </div>
            )}
        </div>
    );
}

const mapState = ({ bag, status }: IReduxStore) => ({
    cards: bag.cards,
    editable: status === STATUS.IDLE && bag.editable,
});

export default connect(mapState)(BagItem);
