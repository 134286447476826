import React from 'react';
import Container from './pages/layout/container';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.scss';
import Confirm from './components/confirm/confirm';

console.log('env.', process.env.NODE_ENV);

if (process.env.NODE_ENV !== 'development') {
    console.log = () => {};
}

function App() {
    return (
        <Router>
            <Confirm />
            <Route
                path={'/' + ':groupId' + '/' + ':merchantId/'}
                render={(props) => <Container {...props} />}
            />
        </Router>
    );
}

export default App;
