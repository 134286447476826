import React, { useState } from 'react';
import { Row, Col } from 'antd';
import './order-summary.scss';
import { IOrderSummary, IRetailer, IReduxStore } from '../../components/common/types';
import { Text, TextTypes, Button, ButtonVariants } from 'spoton-lib';
import {
    ORDER_SUCCESS_MSG,
    ORDER_CONFIRMATION_NUM,
    DISCLAIMER,
    ORDER_FAILED_MSG,
    ORDER_REF_NUM,
} from '../../components/common/constants';
import Utils from '../../components/common/utils';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CardDesktop from '../../components/order-card/card-desktop';
import CardMobile from '../../components/order-card/card-mobile';
import ReactGA from 'react-ga';
import PageNotFound from '../404/404';

interface IOrderSummaryProps {
    order: IOrderSummary;
    backButton: (label: string) => JSX.Element;
    retailer: IRetailer;
}

function OrderSummary({ order, ...props }: IOrderSummaryProps) {
    const [status404, setStatus404] = useState(false);
    const history = useHistory();
    const {
        OrderSuccessMsg,
        Disclaimer,
        BackToMenuLink,
        SendMoreGcBtnText,
    } = Utils.getRetailerProfile(props.retailer);

    const handleHomeClick = () => {
        if (BackToMenuLink) {
            window.location.replace(BackToMenuLink);
            return;
        }
        let { queryParams, merchantId } = props.retailer;
        ReactGA.event({
            category: 'egiftcard flow ' + queryParams.source,
            action: 'exit_to_menu',
            label: merchantId,
        });
        let returnUrl = Utils.tryDecode(queryParams['returnUrl']);
        if (returnUrl) {
            window.location.replace(returnUrl);
        } else {
            setStatus404(true);
        }
    };

    return status404 ? (
        <PageNotFound history={history} />
    ) : (
        <div className="orderConfirmation">
            <Row justify="space-between">
                <Col>
                    {props.backButton(SendMoreGcBtnText || 'Send More E-Gift Cards')}

                    <Text type={TextTypes.H3} className="pageTitle mb16">
                        Order Confirmation
                    </Text>
                </Col>
                <Col>
                    <Button
                        variant={ButtonVariants.PRIMARY}
                        className="homeButton dsmNone"
                        onClick={handleHomeClick}
                    >
                        Go to Home
                    </Button>
                </Col>
            </Row>
            <br />
            <div className="orderInfo dsmNone">
                <Text type={TextTypes.H4} className="confirmMsgTxt mb8">
                    {order?.status ? OrderSuccessMsg || ORDER_SUCCESS_MSG : ORDER_FAILED_MSG}
                </Text>
                <Text type={TextTypes.SUB1} className="confirmOrderNum">
                    {order?.status ? ORDER_CONFIRMATION_NUM : ORDER_REF_NUM}
                    <span className="orderNum">
                        {order?.status ? order?.orderNumber : order?.referenceNumber || ''}
                    </span>
                </Text>
            </div>
            <div className="orderInfo dNone dsmBlock">
                <Text type={TextTypes.H5} className="confirmMsgTxt mb8">
                    {order?.status ? OrderSuccessMsg || ORDER_SUCCESS_MSG : ORDER_FAILED_MSG}
                </Text>
                <Text type={TextTypes.P} className="confirmOrderNum">
                    {order?.status ? ORDER_CONFIRMATION_NUM : ORDER_REF_NUM}
                    <span className="orderNum">
                        {order?.status ? order?.orderNumber : order?.referenceNumber || ''}
                    </span>
                </Text>
            </div>
            <div className="dsmNone">
                {order.giftCards.map((carddet, i) => (
                    <CardDesktop card={carddet} senderEmail={order.senderEmail} key={i} />
                ))}
            </div>
            <div className="dNone dsmBlock">
                {order.giftCards.map((carddet, i) => (
                    <CardMobile card={carddet} senderEmail={order.senderEmail} key={i} />
                ))}
            </div>
            <Text type={TextTypes.P} className="disclaimer">
                {Disclaimer || DISCLAIMER}
                <a href="mailto:restaurantpos@spoton.com">restaurantpos@spoton.com</a>
            </Text>
            <Button
                variant={ButtonVariants.SECONDARY}
                className="homeButton dNone dsmBlock"
                onClick={handleHomeClick}
            >
                Go to Home
            </Button>
        </div>
    );
}

const mapState = (state: IReduxStore) => ({
    retailer: state.retailer,
    cards: state.bag.cards,
});

export default connect(mapState)(OrderSummary);
