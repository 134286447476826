import React, { RefObject, useCallback } from 'react';
import { Switch, Route, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import CardDesigns from '../card-designs/card-designs';
import CardPersonalize from '../card-personalize/card-personalize';
import Checkout from '../checkout/checkout';
import OrderSummary from '../order-summary/order-summary';
import Utils from '../../components/common/utils';
import { PAGEVIEWS, STATUS } from '../../components/common/constants';
import { Button, ButtonVariants, LoadingOverlay, IconButton, PaymentForm } from 'spoton-lib';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { IGiftCard, ILocation, IReduxStore } from '../../components/common/types';

interface IContentProps extends RouteComponentProps {
    paymentForm: RefObject<PaymentForm>;
    handleBackClick: (url: string) => void;
    cards: IGiftCard[];
    status: string;
    bagVisible: boolean;
}

function Content({ cards, status, match, ...props }: IContentProps) {
    const { state, pathname } = props.location as ILocation;

    const isValidState = () => {
        if (state?.promoConfig) {
            const promoCards = Utils.getPromoCards(cards);
            return (state.promoConfig.originalQty as number) > promoCards.length;
        }
        if (state?.eGiftCard) {
            const { lineItemId } = state.eGiftCard;
            const isRemoved = cards.findIndex((item) => item.lineItemId === lineItemId) === -1;
            return lineItemId === 0 || !isRemoved;
        }
        if (pathname.includes(PAGEVIEWS.CARDBILLING)) {
            return cards.length > 0;
        }
        return state?.order;
    };

    const backButton = useCallback(
        (label: string) => {
            let path = match.url;
            if (pathname.includes(PAGEVIEWS.PROMOCARDEDIT)) {
                path = Utils.removeTrailSlash(path) + PAGEVIEWS.CARDBILLING;
            }
            return (
                <Row align="middle" className="backButton dsmNone">
                    <IconButton
                        size={20}
                        alt="back icon"
                        name="BackIcon"
                        className="backIcon"
                        onClick={() => props.handleBackClick(path)}
                        buttonProps={{
                            'aria-label': 'Back',
                        }}
                    />
                    <Button
                        variant={ButtonVariants.SECONDARY}
                        onClick={() => props.handleBackClick(path)}
                        className="button2"
                    >
                        {label}
                    </Button>
                </Row>
            );
        },
        [pathname, props.handleBackClick]
    );

    if (status === STATUS.FAILED) {
        return (
            <div className="errorMain">
                <h2>{'Unable to load content due to some technical issue.'}</h2>
                <Button className="btnGoBack" onClick={() => props.history.goBack()}>
                    Go Back
                </Button>
            </div>
        );
    }

    return (
        <React.Fragment>
            {status === STATUS.LOADING && (
                <LoadingOverlay className={props.bagVisible ? 'loaderLeft' : 'loader'} />
            )}
            <Switch>
                <Route
                    exact
                    path={[
                        Utils.removeTrailSlash(`${match.url}`) + PAGEVIEWS.CARDPERSONALIZATION,
                        Utils.removeTrailSlash(`${match.url}`) +
                            '/promo' +
                            PAGEVIEWS.CARDPERSONALIZATION +
                            '/:index',
                    ]}
                    render={(routeProps) => {
                        return isValidState() ? (
                            <CardPersonalize
                                {...routeProps}
                                eGiftCard={state.eGiftCard}
                                promoConfig={state.promoConfig}
                                backButton={backButton}
                            />
                        ) : (
                            <Redirect to={{ pathname: match.url }} />
                        );
                    }}
                />
                <Route
                    exact
                    path={[
                        Utils.removeTrailSlash(`${match.url}`),
                        Utils.removeTrailSlash(`${match.url}`) + '/promo',
                    ]}
                    render={(routeProps) => {
                        return <CardDesigns {...routeProps} />;
                    }}
                />
                <Route
                    exact
                    path={Utils.removeTrailSlash(`${match.url}`) + PAGEVIEWS.CARDBILLING}
                    render={(routeProps) => {
                        const newProps = {
                            ...routeProps,
                            backButton,
                            paymentForm: props.paymentForm,
                        };
                        return isValidState() ? (
                            <Checkout {...newProps} />
                        ) : (
                            <Redirect to={{ pathname: match.url }} />
                        );
                    }}
                />
                <Route
                    exact
                    path={Utils.removeTrailSlash(`${match.url}`) + PAGEVIEWS.ORDERSUMMARY}
                    render={(routeProps) => {
                        return isValidState() ? (
                            <OrderSummary
                                {...routeProps}
                                order={state.order}
                                backButton={backButton}
                            />
                        ) : (
                            <Redirect to={{ pathname: match.url }} />
                        );
                    }}
                />
            </Switch>
        </React.Fragment>
    );
}

const mapState = ({ bag, status }: IReduxStore) => ({
    cards: bag.cards,
    bagVisible: bag.visible,
    status,
});

export default withRouter(connect(mapState)(Content));
