import React, { useState, useContext, useEffect, memo } from 'react';
import { Text, TextTypes, Input, TipSelector } from 'spoton-lib';
import { Row } from 'antd';
import { IRetailer, IGiftCard, IReduxStore } from '../../common/types';
import { FormContext } from '../../form-wrapper/form-wrapper';
import Utils from '../../common/utils';
import FormUtils from '../../common/form-utils';
import { connect } from 'react-redux';
import './price-input.scss';

interface IPriceInputProps {
    eGiftCard: IGiftCard;
    retailer: IRetailer;
    totalAmount: string;
}

function PriceInput({ retailer, ...props }: IPriceInputProps) {
    const { input, setFormState, decorate } = useContext(FormContext);
    const { MinGcValue, MaxGcValue } = Utils.getRetailerProfile(retailer);
    const { denomination } = props.eGiftCard;
    const priceTags = Utils.getPriceTags(retailer);
    const isCustom = priceTags.every((x: any) => x.label.slice(1) !== denomination);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        setAmount(denomination && !isCustom ? parseInt(denomination) : 0);
    }, [props.eGiftCard]);

    const validateAmount = (value: string) => {
        return FormUtils.validateGcValue(value, {
            prev: denomination || '0',
            total: props.totalAmount,
            min: MinGcValue,
            max: MaxGcValue,
        });
    };

    /**
     * Handle price tag selection
     * @param value amount
     */
    const selectPriceTag = (value: number) => {
        setFormState('input', { denomination: '$' + value });
        setAmount(value);
        let message = validateAmount('$' + value);
        if (message.length) {
            setFormState('valid', { denomination: false });
            setFormState('message', { denomination: message });
        } else {
            setFormState('valid', { denomination: true });
        }
    };

    return (
        <Row className="priceInput">
            <div className="priceTags">
                <Text type={TextTypes.H5} className="inputLabelTop mb8">
                    Amount
                </Text>
                <TipSelector
                    options={priceTags}
                    selectedOption={{
                        label: input.denomination,
                        value: amount,
                    }}
                    onChange={({ value }) => selectPriceTag(value)}
                    allowCustom={false}
                />
            </div>
            <div className="customPrice">
                {decorate('denomination', {
                    initial: '$' + denomination,
                    variant: (value: string) => {
                        setAmount(0);
                        let temp = parseFloat(value.slice(1));
                        return '$' + (isNaN(temp) ? '' : value.slice(1));
                    },
                    validator: (value: string) => validateAmount(value),
                })(<Input label="Custom" value={amount > 0 ? '$' : input.denomination} />)}

                <Text type={TextTypes.LABEL2} className="inputLabelBottom">
                    Min ${parseFloat(MinGcValue).toFixed(2)}
                </Text>
            </div>
        </Row>
    );
}

const mapState = ({ retailer, bag }: IReduxStore) => ({
    retailer,
    totalAmount: Utils.getTotalAmount(bag.cards),
});

export default connect(mapState)(memo(PriceInput));
