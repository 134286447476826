import React, { memo } from 'react';
import { Layout } from 'antd';
import { Text, TextTypes, Button, ButtonVariants, IconButton, Icon } from 'spoton-lib';
import { IReduxStore } from '../../common/types';
import { PAGEVIEWS, STATUS } from '../../common/constants';
import { connect } from 'react-redux';
import { toggleBagView, toggleEditView } from '../../../store/shopping-bag/actions';
import './bag-header.scss';

const { Header } = Layout;

interface IBagHeaderProps {
    location: any;
    editable: boolean;
    bagSize: number;
    closeBagView: () => void;
    toggleEditView: (editable: boolean) => void;
}

function BagHeader(props: IBagHeaderProps) {
    const { pathname } = props.location;
    const isCheckoutPage = pathname.includes(PAGEVIEWS.CARDBILLING);
    return (
        <>
            {/* desktop header */}
            <Header className="bagHeader dsmNone">
                <div />
                <Text type={TextTypes.H5} className="bagTitle">
                    {isCheckoutPage ? 'Order Summary' : `Cart (${props.bagSize})`}
                </Text>
                {!isCheckoutPage ? (
                    <IconButton
                        size={20}
                        alt="close icon"
                        name="CancelIcon"
                        className={'closeIcon'}
                        onClick={() => props.closeBagView()}
                        buttonProps={{
                            'aria-label': 'Close',
                        }}
                    />
                ) : (
                    <div />
                )}
            </Header>
            {/* mobile header */}
            <Header className="bagHeader dNone dsmFlex">
                <IconButton
                    size={20}
                    alt="back icon"
                    name="LeftIcon"
                    className={'backIcon'}
                    onClick={() => props.closeBagView()}
                    buttonProps={{
                        'aria-label': 'Back',
                    }}
                />
                <Text type={TextTypes.H3} className="bagTitle">
                    My Cart ({props.bagSize})
                </Text>
                {props.bagSize > 0 ? (
                    <div
                        className="editButtonRow"
                        onClick={() => props.toggleEditView(!props.editable)}
                    >
                        {!props.editable && (
                            <Icon
                                color="#1769FF"
                                name="EditIcon"
                                className="editIcon"
                                alt="edit icon"
                                size={20}
                            />
                        )}
                        <Button
                            variant={ButtonVariants.TERTIARY}
                            onClick={() => null}
                            className="editButton"
                        >
                            {props.editable ? 'Save' : 'Edit'}
                        </Button>
                    </div>
                ) : (
                    <div />
                )}
            </Header>
        </>
    );
}

const mapState = ({ bag, status }: IReduxStore) => ({
    editable: status === STATUS.IDLE && bag.editable,
    bagSize: bag.cards.length,
});

const mapDispatch = {
    closeBagView: () => toggleBagView(false),
    toggleEditView,
};

export default connect(mapState, mapDispatch)(memo(BagHeader));
