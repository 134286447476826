import React, { useEffect, useContext, useMemo, memo } from 'react';
import './form-container.scss';
import { Text, TextTypes, Input, Radio, TextArea, Button } from 'spoton-lib';
import { Row } from 'antd';
import { IGiftCard, IPromoConfig } from '../../common/types';
import { FORM_TYPES } from '../../common/constants';
import Utils from '../../common/utils';
import FormUtils from '../../common/form-utils';
import { IFormState, IFormProps } from '../../form-wrapper/types';
import { FormContext } from '../../form-wrapper/form-wrapper';
// import moment from 'moment';
import SingleDatePicker from '../date-picker/date-picker';
import TimePicker from '../time-picker/time-picker';
import PriceInput from '../price-input/price-input';
import '../time-picker/time-picker';

interface IFormContainerProps {
    eGiftCard: IGiftCard;
    promoConfig: IPromoConfig;
    formType: string;
    setFormType: (type: string) => void;
    handleFormSubmit: () => void;
}

const { MYSELF, SOMEONE_ELSE } = FORM_TYPES;

function FormContainer(props: IFormContainerProps) {
    const { eGiftCard, promoConfig, formType } = props;
    const editMode = eGiftCard.lineItemId > 0;
    const form: IFormProps = useContext(FormContext);
    const { decorate, input, setFormState, loading } = form;
    const card = eGiftCard.card;
    const senderInfo = Utils.getSenderInfo();
    const {
        personalMessage,
        senderInfo: { senderName, shipToSelf },
        recipientInfo: { recipientName, recipientEmail },
        customDate,
        customTime,
    } = eGiftCard;

    // check if current form type is different than initial
    const isFormChanged = () => {
        if (shipToSelf === 'Y') {
            return formType === SOMEONE_ELSE;
        } else {
            return formType === MYSELF;
        }
    };

    useEffect(() => {
        if (shipToSelf === 'Y' || promoConfig?.allToMe) {
            props.setFormType(MYSELF);
        } else {
            props.setFormType(SOMEONE_ELSE);
        }
    }, [eGiftCard]);

    useEffect(() => {
        form.initialize();
    }, [eGiftCard, formType]);

    useEffect(() => {
        if (Utils.isPromoCard(card)) {
            if (!loading && isFormChanged()) {
                if (formType === SOMEONE_ELSE) {
                    setFormState('input', {
                        recipientName: '',
                        recipientEmail: '',
                        confirmemail: '',
                        senderName: '',
                    });
                }
                setFormState('initial', {});
            }
        }
    }, [loading]);

    const handleFormChange = ({ target }: React.SyntheticEvent) => {
        let value = (target as HTMLInputElement).value;
        props.setFormType(value);
    };

    let buttonText = editMode ? 'Save Changes' : 'Add to Cart';
    if (props.promoConfig) {
        buttonText = 'Save & Continue';
    }

    const labels = {
        recipientName: "Recipient's Name",
        recipientEmail: "Recipient's Email",
        confirmemail: "Confirm Recipient's Email",
    };
    const values = {
        recipientName,
        recipientEmail,
        confirmemail: recipientEmail,
    };

    if (formType === MYSELF) {
        labels.recipientName = 'Name';
        labels.recipientEmail = 'Email';
        labels.confirmemail = 'Confirm Email';
        values.recipientName = senderInfo.senderName;
        values.recipientEmail = senderInfo.senderEmail;
        values.confirmemail = senderInfo.senderEmail;
    }

    const footer = (
        <Button
            onClick={props.handleFormSubmit}
            disabled={!useMemo(form.isFormValid, [input, form.initial])}
            className="submitBtn"
        >
            <>{buttonText}</>
        </Button>
    );

    const isPromoCard = Utils.isPromoCard(card);

    return (
        <Row className="formContainer">
            {isPromoCard && (
                <Row className="radioGroup">
                    <div className="radioButtons">
                        <Text type={TextTypes.H5} className="inputLabelTop">
                            {promoConfig?.allToMe ? 'Contact Details' : 'Send to'}
                        </Text>
                        {!promoConfig?.allToMe && (
                            <Row>
                                <Radio
                                    checked={formType === SOMEONE_ELSE}
                                    name="someone-else"
                                    value={SOMEONE_ELSE}
                                    label="Someone else"
                                    onChange={handleFormChange}
                                    className="radio"
                                />
                                <Radio
                                    checked={formType === MYSELF}
                                    name="myself"
                                    value={MYSELF}
                                    label="Myself"
                                    onChange={handleFormChange}
                                    className="radio"
                                />
                            </Row>
                        )}
                    </div>
                    {formType === SOMEONE_ELSE && <div className="radioMargin" />}
                </Row>
            )}
            {!isPromoCard && <PriceInput eGiftCard={eGiftCard} />}
            <div className="formLeft">
                {decorate('recipientName', {
                    initial: values.recipientName,
                })(<Input label={labels.recipientName} />)}

                {decorate('recipientEmail', {
                    initial: values.recipientEmail,
                    validator: (value: string) => FormUtils.validateEmail(value),
                })(<Input label={labels.recipientEmail} />)}

                {decorate('confirmemail', {
                    initial: values.recipientEmail,
                    validator: (value: string, state: IFormState) => {
                        const { recipientEmail } = state.input;
                        if (recipientEmail !== value) {
                            return 'Recipient emails do not match.';
                        }
                        return '';
                    },
                })(<Input label={labels.confirmemail} />)}
                {formType === SOMEONE_ELSE && (
                    <>
                        <Text type={TextTypes.LABEL2} className="inputLabelTop">
                            Optional
                        </Text>
                        {decorate('personalMessage', {
                            initial: personalMessage,
                            required: false,
                        })(
                            <TextArea
                                label="Personal Message"
                                onChange={(event) => {
                                    const { value } = event.target as HTMLInputElement;
                                    const { personalMessage } = input;
                                    const isValid = FormUtils.validateMessage(value);
                                    setFormState('input', {
                                        personalMessage: isValid ? value : personalMessage,
                                    });
                                }}
                                className="textArea"
                            />
                        )}
                        <Text type={TextTypes.LABEL2} className="inputLabelBottom">
                            {120 - (input.personalMessage?.length || 0) + ' Characters'}
                        </Text>
                    </>
                )}
                {formType === MYSELF && footer}
            </div>
            {formType === SOMEONE_ELSE && (
                <div className="formRight">
                    {decorate('senderName', { initial: senderName })(
                        <Input label="Sender's Name" />
                    )}
                    <Text type={TextTypes.LABEL2} className="inputLabelTop">
                        Optional
                    </Text>
                    <Text type={TextTypes.LABEL2} className="inputLabel mb4">
                        Delivery Date
                    </Text>
                    {decorate('customDate', { initial: customDate, required: false })(
                        <SingleDatePicker />
                    )}
                    <Text type={TextTypes.LABEL2} className="inputLabelTop">
                        Optional
                    </Text>
                    <Text type={TextTypes.LABEL2} className="inputLabel mb4">
                        Time
                    </Text>
                    {decorate('customTime', {
                        initial: customTime?.split(' ')[1],
                        required: false,
                        validator: (value: string, state: IFormState) => {
                            const { customDate, meridian } = state.input;
                            const time = value + ' ' + meridian;
                            return FormUtils.validateTime(customDate, time);
                        },
                    })(<TimePicker defaultValue={customTime} />)}
                    {footer}
                </div>
            )}
        </Row>
    );
}

export default memo(FormContainer);
