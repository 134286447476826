import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import {
    IRetailer,
    ICategory,
    IPayment,
    IOrderSummary,
    IPromoConfig,
} from '../components/common/types';
import { API_GRAPHQL_URL } from '../components/common/constants';

const instance = new ApolloClient<{}>({
    uri: API_GRAPHQL_URL,
});

export default class ApolloService {
    _instance: ApolloClient<{}>;

    constructor() {
        this._instance = instance;
    }

    private handleException(ex?: any) {
        if (ex?.graphQLErrors?.length) {
            if (ex.networkError) {
                console.log('network error', ex.networkError);
                return { status: false, message: 'network error' };
            }
        }
        return { status: false, message: 'unknown error' } as any;
    }

    /**
     * Get retailer info by groupid and merchantid
     * @param groupid
     * @param merchantid
     */
    public async getRetailer(groupid: string, merchantid: string) {
        try {
            console.log('getRetailer...', groupid);
            let data = await this._instance.query<{ retailer: IRetailer }>({
                query: gql`
                    query retailer($groupid: String!, $merchantid: String!) {
                        retailer(groupid: $groupid, merchantid: $merchantid) {
                            retailerId
                            groupId
                            merchantId
                            transId
                            retailerName
                            retailerLogo
                            retailerActive
                            retailerProfiles {
                                profileName
                                profileValue
                            }
                            tokenKey
                            customStyle
                            bannerName
                            banner {
                                mobile {
                                    title
                                    subtitle
                                    disclaimer
                                    titleStyle
                                    subtitleStyle
                                    disclaimerStyle
                                    url
                                }
                                desktop {
                                    title
                                    subtitle
                                    disclaimer
                                    titleStyle
                                    subtitleStyle
                                    disclaimerStyle
                                    url
                                }
                            }
                            status
                            message
                        }
                    }
                `,
                variables: {
                    groupid,
                    merchantid,
                },
                fetchPolicy: 'no-cache',
            });
            console.log('getRetailer...', data.data);
            return data.data
                ? data.data.retailer
                : {
                      status: false,
                      message: 'Failed to get retailer',
                  };
        } catch (ex) {
            return this.handleException(ex);
        }
    }

    /**
     * Get card designs by retailerid
     * @param retailerid
     */
    public async getCardDesigns(retailerid: number) {
        try {
            let data = await this._instance.query<{
                cardDesigns: Array<ICategory>;
            }>({
                query: gql`
                    query cardDesigns($retailerid: Int!) {
                        cardDesigns(retailerid: $retailerid) {
                            categoryId
                            description
                            displayOrder
                            cards {
                                cardId
                                description
                                imageName
                                displayOrder
                                styles
                                url
                            }
                        }
                    }
                `,
                variables: {
                    retailerid,
                },
                fetchPolicy: 'no-cache',
            });
            return data.data ? data.data.cardDesigns : [];
        } catch (ex) {
            return [];
        }
    }

    /**
     * Create order and return its summary
     * @param input payload
     */
    public async createOrder(input: IPayment) {
        try {
            let data = await this._instance.mutate<{ createOrder: IOrderSummary }>({
                mutation: gql`
                    mutation createOrder($input: OrderInput!) {
                        createOrder(input: $input) {
                            orderNumber
                            status
                            message
                            referenceNumber
                            senderEmail
                        }
                    }
                `,
                variables: {
                    input,
                },
            });
            return data.data
                ? data.data.createOrder
                : {
                      status: false,
                      message: 'Failed to create order',
                  };
        } catch (ex) {
            return this.handleException(ex);
        }
    }

    /**
     * Get tracking ID for google analytics
     */
    public async getTrackingID() {
        try {
            let data = await this._instance.query<{
                analytics: { trackingID: string };
            }>({
                query: gql`
                    query analytics {
                        analytics {
                            trackingID
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            });
            return data.data
                ? data.data.analytics
                : {
                      status: false,
                      message: 'Failed to get tracking ID',
                  };
        } catch (ex) {
            return this.handleException(ex);
        }
    }

    /**
     * Get promo config by total amount
     * @param groupid
     * @param merchantid
     * @param total total amount
     */
    public async getPromoCard(groupid: string, merchantid: string, total: number) {
        try {
            let data = await this._instance.query<{ getPromoCard: IPromoConfig }>({
                query: gql`
                    query getPromoCard($input: PromoInput) {
                        getPromoCard(input: $input) {
                            quantity
                            promoAmount
                            cards {
                                cardId
                                description
                                imageName
                                styles
                                displayOrder
                                cardType
                                url
                            }
                            status
                            message
                        }
                    }
                `,
                variables: {
                    input: {
                        groupid,
                        merchantid,
                        total,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            return data.data
                ? data.data.getPromoCard
                : {
                      status: false,
                      message: 'Failed to get promo card',
                  };
        } catch (ex) {
            return this.handleException(ex);
        }
    }
}
