export const API_BASE_URL =
    process.env.NODE_ENV === 'production'
        ? (window as any)._env_.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL;

export const API_GRAPHQL_URL = API_BASE_URL ? API_BASE_URL + '/graphql' : '/graphql';

const S3_REGION =
    process.env.NODE_ENV === 'production'
        ? (window as any)._env_.REACT_APP_S3_REGION
            ? '.s3.' + (window as any)._env_.REACT_APP_S3_REGION
            : process.env.REACT_APP_S3_REGION
            ? '.s3.' + process.env.REACT_APP_S3_REGION
            : '.s3'
        : '.s3';

const S3_BUCKET_NAME =
    process.env.NODE_ENV === 'production'
        ? (window as any)._env_.REACT_APP_S3_BUCKET_NAME
        : process.env.REACT_APP_S3_BUCKET_NAME
        ? process.env.REACT_APP_S3_BUCKET_NAME
        : '';

export const API_IMAGES_PATH = 'https://' + S3_BUCKET_NAME + S3_REGION + '.amazonaws.com/images/';
console.log('API_IMAGES_PATH', API_IMAGES_PATH);

export const API_STYLES_PATH = 'https://' + S3_BUCKET_NAME + S3_REGION + '.amazonaws.com/styles/';

export const POWERED_BY_LOGO = API_IMAGES_PATH + 'poweredBy.svg';

export const STATUS = {
    LOADING: 'loading',
    FAILED: 'failed',
    IDLE: 'idle',
};

export const PAGEVIEWS = {
    CARDPERSONALIZATION: '/cardpersonalization',
    CARDBILLING: '/billing',
    MOBILEBAG: '/mobilebag',
    ORDERSUMMARY: '/ordersummary',
    PROMOCARDEDIT: '/promo/cardpersonalization',
};

export const DEFAULT_MESSAGE_TEXT_STYLE = {
    font: 'Poppins',
    color: '#525656',
    size: 20
};

export const DEFAULT_AMOUNT_TEXT_STYLE = {
    font: 'Poppins',
    color: '#525656',
    size: 36
};

export const DEFAULT_BANNER_BACKGROUND = '#e6ffec';
export const DEFAULT_BANNER_TEXT_STYLE = {
    font: 'Poppins',
    color: 'white',
    fontStyle: 'bold',
    size: "36"
};

export const DEFAULT_MOBILE_BANNER_TEXT_SIZE = {
    title: 17,
    subtitle: 12,
    disclaimer: 10
}

export const DEFAULT_PRICE_TAGS = [
    { label: '$25', value: 25 },
    { label: '$50', value: 50 },
    { label: '$75', value: 75 },
    { label: '$100', value: 100 },
];

export const FORM_TYPES = {
    MYSELF: 'myself',
    SOMEONE_ELSE: 'someone-else',
};

export const EGIFTCARD_TEXT = 'e-Gift Cards';
export const ORDERCONFIRM_TEXT = 'Order Confirmation';
export const ORDERFAILURE_TEXT = 'Order Error';
export const ORDER_SUCCESS_MSG = 'Your order has been successfully placed!';
export const ORDER_FAILED_MSG = 'Your order failed due to some reason!';
export const ORDER_CONFIRMATION_NUM = 'Confirmation number: ';
export const ORDER_REF_NUM = 'Please contact customer care with order reference number: ';
export const DISCLAIMER =
    'Your e-Gift Card will be sent within 24 hours of placing order or in the delivery date selected. \
A confirmation email with the order number will be sent to you when it has been delivered. \
If you do not receive a confirmation, this may indicate a problem with your order and \
you may contact Customer Service at ';
