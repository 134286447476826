import { showConfirm } from './confirm';
import { IRemoveGiftCard, IRemovePromoCard, INotifyDiscardEdit, INotifyPromoCards } from './types';
import { IOrderSummary } from '../common/types';
import { store } from '../../store/index';
import { removeCard, updatePromoCards } from '../../store/shopping-bag/actions';
import Utils from '../common/utils';
import ReactGA from 'react-ga';
import _ from 'lodash';

const dispatch = (action: any) => store.dispatch(action);

/**
 * Remove one or all gift cards
 * @param props
 */
export function removeGiftCard(props: IRemoveGiftCard) {
    const { lineItemId } = props;
    showConfirm({
        title: lineItemId > -1 ? 'Delete E-Gift Card' : 'Delete all E-Gift Cards',
        body:
            lineItemId > -1
                ? 'Are you sure you want to delete this\n e-gift card?'
                : 'Are you sure you want to delete all gift cards?',
        confirmText: 'Delete',
        onConfirm: () => {
            dispatch(removeCard(lineItemId));
            props.callback();
        },
        confirmStyle: 'removeBtn',
    });
}

/**
 * Remove gift card with promo cards
 * @param props
 */
export function removePromoCard(props: IRemovePromoCard) {
    const { allCards, promoConfig } = props;
    const promoCards = Utils.getPromoCards(allCards);
    const removeQty = promoCards.length - promoConfig.quantity;
    showConfirm({
        title: 'Delete E-Gift Card?',
        body:
            removeQty <= 0
                ? 'Are you sure you want to delete this\n e-gift card?'
                : `Your order will not meet the minimum and you will lose your ${removeQty} free gift card${
                      removeQty > 1 ? 's' : ''
                  }.`,
        confirmText: 'Delete',
        onConfirm: () => {
            dispatch(updatePromoCards(promoConfig));
            props.callback();
        },
        confirmStyle: 'removeBtn',
    });
}

/**
 * Notify user that changes will not be saved
 * @param props
 */
export function notifyDiscardEdit(props: INotifyDiscardEdit) {
    const { input, initial } = props.form;
    if (!_.isEqual(initial, input)) {
        showConfirm({
            title: 'Changes will not be saved',
            body: 'Are you sure you want to go back? Your changes will not be saved.',
            confirmText: 'Confirm',
            onConfirm: () => {
                const { queryParams, merchantId } = props.retailer;
                if (initial.denomination === '$') {
                    ReactGA.event({
                        category: queryParams.source,
                        action: 'return_to_template',
                        label: merchantId,
                    });
                }
                props.callback();
            },
        });
    } else {
        props.callback();
    }
}

/**
 * Notify user about promo cards he earned
 * @param props
 */
export function notifyPromoCards(props: INotifyPromoCards) {
    const { promoConfig } = props;
    const { quantity, promoAmount } = promoConfig;
    showConfirm({
        title: `You've earned ${quantity > 1 ? quantity : 'a'} Free $${promoAmount} E-Gift Card${
            quantity > 1 ? 's' : ''
        }!`,
        body: 'Send it to someone or keep it for yourself.',
        confirmText: quantity === 1 ? 'Continue' : 'Edit Cards',
        onConfirm: () => props.callback(promoConfig, false),
        onCancel: () => props.callback(promoConfig, true),
        hideCancel: quantity === 1,
        className: 'promptPromo',
        cancelText: 'Send All to Me',
    });
}

/**
 * Notify user about payment failed
 * @param props
 */
export function notifyPaymentFailed(props?: IOrderSummary) {
    showConfirm({
        title: 'Payment Failed REFID: ' + (props?.referenceNumber || 'N/A'),
        body: props?.message || 'Something went wrong.Please try again.',
        confirmText: 'Ok',
        onConfirm: () => null,
        hideCancel: true,
    });
}
