import React, { memo } from 'react';
import { Card, TextTypes } from 'spoton-lib';
import { Row, Col } from 'antd';
import './order-card.scss';
import { TextGroup } from './text-group';
import { IGiftCard } from '../common/types';
import DateUtils from '../common/date-utils';
import Utils from '../common/utils';

interface CardDesktopProps {
    card: IGiftCard;
    senderEmail: string;
}

const CardDesktop = ({ card, ...props }: CardDesktopProps) => {
    const sender = card.senderInfo;
    const recipient = card.recipientInfo;
    return (
        <Card className={'orderCard'}>
            <Row>
                <Col span={6}>
                    <img
                        alt="card image"
                        src={card.cardImageUrl + Utils.getRandomString()}
                        className="imageBox"
                    />
                </Col>
                <Col span={2} />
                <Col span={16}>
                    <Row>
                        <TextGroup
                            span={8}
                            label="Gift Card Amount"
                            value={'$' + parseFloat(card.denomination).toFixed(2)}
                            valueType={TextTypes.H5}
                        />
                        <TextGroup
                            span={8}
                            label="Delivery Date"
                            value={DateUtils.getCustomDate(card.customDate)}
                            valueType={TextTypes.H5}
                        />
                        <TextGroup
                            span={8}
                            label="Time"
                            value={DateUtils.getCustomTime(card.customTime)}
                            valueType={TextTypes.H5}
                        />
                    </Row>
                    <Row />
                    <Row>
                        <TextGroup
                            span={8}
                            label="Recipient"
                            value={
                                <span className="multiline">
                                    <span>{recipient.recipientName}</span>
                                    <span>
                                        {sender.shipToSelf === 'Y'
                                            ? props.senderEmail
                                            : recipient.recipientEmail}
                                    </span>
                                </span>
                            }
                        />
                        <TextGroup
                            span={8}
                            label="Sender"
                            value={
                                <span className="multiline">
                                    <span>{sender.senderName}</span>
                                    <span>{props.senderEmail}</span>
                                </span>
                            }
                        />
                        {card.personalMessage ? (
                            <TextGroup span={8} label="Message" value={card.personalMessage} />
                        ) : (
                            <Col span={8} />
                        )}
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default memo(CardDesktop);
