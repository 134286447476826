import _ from 'lodash';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import DateUtils from './date-utils';
import moment from 'moment';

const phoneValidator = PhoneNumberUtil.getInstance();

export default class FormUtils {
    static validateEmail(email: string): string {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
            return 'Please enter valid email';
        }
        return '';
    }

    static validateName(name: string): string {
        let re = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/;
        if (!re.test(name)) {
            return 'Please enter valid name';
        }
        return '';
    }

    static validateMessage(msg: string): boolean {
        let re = /^(.|\r\n|\n|\r){0,120}?$/;
        return re.test(msg) && msg.split('\n').length < 3;
    }

    static validateGCValue(amount: string): boolean {
        let re = /^\d+(?:\.\d{0,2})?$/;
        return re.test(amount);
    }

    static validateTelephone(phone: string): string {
        try {
            let number = phoneValidator.parse(phone, 'US');
            return phoneValidator.format(number, PhoneNumberFormat.NATIONAL);
        } catch (err) {
            return phone;
        }
    }

    static validateUSNumber(value: string): string {
        try {
            let number = phoneValidator.parse(value, 'US');
            if (phoneValidator.isValidNumber(number)) {
                return '';
            } else {
                return 'Please enter valid US contact no.';
            }
        } catch (err) {
            return 'Please enter valid US contact no.';
        }
    }

    static validateGcValue(value: string, props: any): string {
        value = value.slice(1);
        let re = /^\d+(?:\.\d{0,2})?$/;
        if (!re.test(value)) {
            return 'Please enter valid amount';
        }
        let current = parseFloat(value);
        if (current < parseFloat(props.min)) {
            return 'Value should be above or equal to $' + props.min;
        }
        let total = parseFloat(props.total) - parseFloat(props.prev) + current;
        if (total > props.max) {
            return 'Exceeds the max amount';
        }
        return '';
    }

    static validateTime(dateString: string, timeString: string): string {
        if (!timeString) return '';
        let time = DateUtils.getTimeString(timeString);
        if (!moment(time).isValid()) {
            return 'Please enter valid time';
        }
        if (DateUtils.getCustomDate(dateString) === 'Today') {
            let date = new Date();
            let local = DateUtils.getLocalDate(date, new Date(time));
            if (local.getTime() < Date.now()) {
                return 'Time cannot be in the past';
            }
        }
        return '';
    }
}
