import React, { useContext, memo } from 'react';
import { Button, NumberFormat } from 'spoton-lib';
import './time-picker.scss';
import { FormContext } from '../../form-wrapper/form-wrapper';
import FormUtils from '../../common/form-utils';
import DateUtils from '../../common/date-utils';
import moment from 'moment';

interface TimePickerProps {
    defaultValue?: string;
}

function TimePicker(props: TimePickerProps) {
    const { input, decorate, setFormState } = useContext(FormContext);
    const defMeridian = props.defaultValue?.slice(-2) || 'AM';
    const { customDate, customTime, meridian } = input;
    const timeString = DateUtils.getTimeString(customTime + ' ' + meridian);

    const handleMeridian = () => {
        let time = customTime;
        if (meridian === 'AM') {
            time += ' PM';
            setFormState('input', { meridian: 'PM' });
        } else {
            time += ' AM';
            setFormState('input', { meridian: 'AM' });
        }
        let error = FormUtils.validateTime(customDate, time);
        if (error.length) {
            setFormState('valid', { customTime: false });
            setFormState('message', { customTime: error });
        } else {
            setFormState('valid', { customTime: true });
            setFormState('message', { customTime: '' });
        }
    };

    const handleFormat = (value: string) => {
        if (value.length === 1) {
            if (value !== '0' && value !== '1') {
                return '0' + value;
            }
            return value;
        }
        if (value.length === 2) {
            if (parseInt(value) > 12) {
                return '0' + value.slice(0, 1);
            }
            return value;
        }
        if (value.length === 4) {
            let hours = value.slice(0, 2);
            let minutes = value.slice(2);
            if (parseInt(minutes) > 59) {
                return hours + ':' + minutes.slice(0, 1);
            }
        }
        return value.slice(0, 2) + ':' + value.slice(2, 4);
    };

    return (
        <div className="timePickerRow">
            <NumberFormat
                {...props}
                format={handleFormat}
                placeholder="00:00"
                className="timePicker"
                allowLeadingZeros={true}
            />
            {decorate('meridian', { initial: defMeridian, required: false })(
                <Button
                    variant="secondary"
                    onClick={handleMeridian}
                    className="meridian"
                    disabled={!moment(timeString).isValid()}
                >
                    {input.meridian}
                </Button>
            )}
        </div>
    );
}

export default memo(TimePicker);
