import { IGiftCard, IPromoConfig } from '../../components/common/types';
import { BagAction, BagActionTypes } from './types';

const toggleBagView = (visible: boolean): BagActionTypes => {
    return {
        type: BagAction.TOGGLE_BAG_VIEW,
        payload: visible,
    };
};

const toggleEditView = (editable: boolean): BagActionTypes => {
    return {
        type: BagAction.TOGGLE_EDIT_VIEW,
        payload: editable,
    };
};

const removeCard = (lineItemId: number): BagActionTypes => {
    return {
        type: BagAction.REMOVE_CARD,
        payload: lineItemId,
    };
};

const addCards = (cards: IGiftCard[]): BagActionTypes => {
    return {
        type: BagAction.ADD_CARDS,
        payload: cards,
    };
};

const updateCard = (card: IGiftCard): BagActionTypes => {
    return {
        type: BagAction.UPDATE_CARD,
        payload: card,
    };
};

const updatePromoCards = (promoConfig: IPromoConfig): BagActionTypes => {
    return {
        type: BagAction.UPDATE_PROMO_CARDS,
        payload: promoConfig,
    };
};

const clearBag = (): BagActionTypes => {
    return {
        type: BagAction.CLEAR_BAG,
        payload: null,
    };
};

export {
    toggleBagView,
    toggleEditView,
    removeCard,
    addCards,
    updateCard,
    updatePromoCards,
    clearBag,
};
