import React, { useState, useRef, useEffect, useCallback, useContext, memo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './card-personalize.scss';
import { Row } from 'antd';
import { Text, TextTypes } from 'spoton-lib';
import FormContainer from '../../components/card-personalize/form-container/form-container';
import { IRetailer, IGiftCard, IPromoConfig, IReduxStore } from '../../components/common/types';
import { PAGEVIEWS, STATUS, FORM_TYPES } from '../../components/common/constants';
import CardView, { ICardViewRef } from '../../components/common/card-view';
import Service from '../../service/apollo-service';
import Utils from '../../components/common/utils';
import DateUtils from '../../components/common/date-utils';
import { FormContext } from '../../components/form-wrapper/form-wrapper';
import { connect } from 'react-redux';
import { setStatus } from '../../store/container/actions';
import {
    addCards,
    updateCard,
    updatePromoCards,
    toggleBagView,
} from '../../store/shopping-bag/actions';
import { removePromoCard } from '../../components/confirm/actions';
import ReactGA from 'react-ga';

interface IPersonalizeProps extends RouteComponentProps {
    eGiftCard: IGiftCard;
    promoConfig: IPromoConfig;
    backButton: (label: string) => JSX.Element;
    cards: IGiftCard[];
    retailer: IRetailer;
    setStatus: (status: string) => void;
    updateCard: (card: IGiftCard) => void;
    addCards: (card: IGiftCard[]) => void;
    updatePromoCards: (config: IPromoConfig) => void;
    openBag: () => void;
}

const { MYSELF, SOMEONE_ELSE } = FORM_TYPES;

function CardPersonalize(props: IPersonalizeProps) {
    const { history, retailer, cards, eGiftCard, promoConfig } = props;
    const { input } = useContext(FormContext);
    const editMode = eGiftCard.lineItemId > 0;
    const card = eGiftCard.card;
    const canvasRef: React.RefObject<ICardViewRef> = useRef(null);
    const imageRef: React.RefObject<HTMLImageElement> = useRef(null);

    const { DefaultPersonalMsg } = Utils.getRetailerProfile(retailer);
    const { denomination, senderInfo, position } = eGiftCard;
    const [showMessage, handleShowMessage] = useState(true);
    const [formType, setFormType] = useState(
        senderInfo.shipToSelf === 'Y' || promoConfig?.allToMe ? MYSELF : SOMEONE_ELSE
    );

    useEffect(() => {
        setTimeout(() => {
            if (imageRef.current) {
                imageRef.current.style.display = 'none';
            }
        }, 800);
        if (!editMode) {
            ReactGA.event({
                category: retailer.queryParams.source,
                action: 'template_selected',
                label: retailer.merchantId,
            });
        }
    }, [eGiftCard]);

    /**
     * Get card object by form type
     * @param lineitemid card index
     */
    const getCardObject = (lineItemId: number): IGiftCard => {
        const { personalMessage, senderName, recipientEmail, recipientName } = input;
        const time = input.customTime + ' ' + input.meridian;
        return {
            ...eGiftCard,
            lineItemId,
            denomination: Utils.isPromoCard(card)
                ? eGiftCard.denomination
                : input.denomination.slice(1),
            personalMessage,
            recipientInfo: { recipientEmail, recipientName },
            senderInfo: {
                senderName: formType === MYSELF ? recipientName : senderName,
                senderEmail: formType === MYSELF ? recipientEmail : '',
                shipToSelf: formType === MYSELF ? 'Y' : 'N',
            },
            merchantId: retailer.merchantId,
            cardImageUrl: Utils.getCardImageUrl(retailer.retailerId, card.imageName, card.url),
            customDate: input.customDate,
            customTime: input.customTime ? DateUtils.getTimeString(time) : '',
        };
    };

    const withCardImage = (card: IGiftCard) => {
        return { ...card, cardImage: canvasRef.current?.getDataURL('image/jpeg') };
    };

    /**
     * Add promo card to shopping bag
     * Add all cards at once if 'alltome' is true
     * @param card promo card
     */
    const submitPromoCard = (card: IGiftCard) => {
        const { quantity } = promoConfig;
        if (promoConfig.allToMe) {
            for (let i = 0; i < quantity; i++) {
                const withIndex = {
                    ...card,
                    lineItemId: card.lineItemId + i + 1,
                };
                props.addCards([withCardImage(withIndex)]);
            }
        } else {
            props.addCards([withCardImage(card)]);
        }
        const { params }: any = props.match;
        const index = parseInt(params.index);
        // redirect to checkout page on complete
        if (promoConfig.allToMe || index === quantity) {
            history.push(retailer.baseUrl + PAGEVIEWS.CARDBILLING);
        } else {
            const { cards, promoAmount } = promoConfig;
            const eGiftCard = Utils.getSelectedCard(cards[0], '' + promoAmount);
            history.push({
                pathname: retailer.baseUrl + `${PAGEVIEWS.PROMOCARDEDIT}/${index + 1}`,
                state: { eGiftCard, promoConfig },
            });
        }
    };

    /**
     * Handle callback by form type
     * @param card gift card | promo card
     * @param callback
     */
    const handleCallback = (card: IGiftCard, callback: Function) => {
        if (formType === SOMEONE_ELSE && !card.personalMessage) {
            handleShowMessage(false); // will update canvas
            // wait for some time to get data url of updated canvas
            setTimeout(() => callback(card), 100);
        } else {
            callback(card);
        }
    };

    const finalCallback = (card: IGiftCard) => {
        if (editMode) {
            props.updateCard(withCardImage(card));
        } else {
            props.addCards([withCardImage(card)]);
            props.openBag();
        }
        history.push(retailer.baseUrl);
    };

    /**
     * Update promo cards by promo config
     * Notify user if promo cards need to be removed
     * @param config promo config
     * @param card gift card
     */
    const updatePromoCards = (config: IPromoConfig, card: IGiftCard) => {
        const promoCards = Utils.getPromoCards(cards);
        if (config.quantity < promoCards.length) {
            removePromoCard({
                allCards: cards,
                promoConfig: config,
                callback: () => handleCallback(card, finalCallback),
            });
        } else {
            props.updatePromoCards(config);
            handleCallback(card, finalCallback);
        }
    };

    /**
     * Add gift card to shopping bag
     * Update promo cards if required
     * @param card gift card
     */
    const submitGiftCard = async (card: IGiftCard) => {
        const promoCards = Utils.getPromoCards(cards);
        if (
            !Utils.isPromoCard(card.card) &&
            promoCards.length &&
            eGiftCard.denomination !== input.denomination
        ) {
            const { groupId, merchantId } = retailer;
            // total = previous total - previous card amount + current card amount
            const total =
                parseFloat(Utils.getTotalAmount(cards)) -
                parseFloat(eGiftCard.denomination || '0') +
                parseFloat(input.denomination.slice(1));
            console.log('Total:', total);
            props.setStatus(STATUS.LOADING);
            const promoConfig = await new Service().getPromoCard(
                groupId || '',
                merchantId || '',
                parseFloat(total.toPrecision(7))
            );
            props.setStatus(STATUS.IDLE);
            if (promoConfig) {
                updatePromoCards(promoConfig, card);
            }
        } else {
            handleCallback(card, finalCallback);
        }
    };

    const handleFormSubmit = useCallback(() => {
        let nLineItemId = 1;
        if (cards.length && !editMode) {
            nLineItemId = Utils.getLastItemId(cards);
        }
        const cardObj = getCardObject(editMode ? eGiftCard.lineItemId : nLineItemId);
        if (formType === MYSELF) {
            const { senderName, senderEmail } = cardObj.senderInfo;
            Utils.setSenderInfo({ senderName, senderEmail });
        }
        if (promoConfig) {
            handleCallback(cardObj, submitPromoCard);
        } else {
            submitGiftCard(cardObj);
        }
    }, [input, cards]);

    let pageTitle = 'Personalize your E-Gift Card';

    if (promoConfig) {
        const { params }: any = props.match;
        pageTitle =
            promoConfig.allToMe || promoConfig.quantity === 1
                ? 'Free Gift Card'
                : Utils.getCardIndex(params.index) + ' Free Gift Card';
    }

    const getCardAmount = () => {
        if (Utils.isPromoCard(card)) {
            return '$' + parseFloat(denomination).toFixed(2);
        } else {
            const amount = (input.denomination || '$').slice(1);
            return '$' + parseFloat(amount || '0').toFixed(2);
        }
    };

    return (
        <div className="cardPersonalize">
            {props.backButton(promoConfig ? 'Checkout' : 'All Cards')}
            <Text type={TextTypes.H4} className="pageTitle mb8">
                {pageTitle}
            </Text>
            <br />
            {/* following image is used for transition */}
            {!editMode && position && (
                <img
                    className="fakeImage"
                    src={Utils.getCardImageUrl(retailer.retailerId, card.imageName, card.url)}
                    style={position}
                    ref={imageRef}
                />
            )}
            <Row>
                <CardView
                    className="imageBox"
                    baseImageURL={Utils.getCardImageUrl(retailer.retailerId, card.imageName, card.url)}
                    width={500}
                    height={315}
                    containerHeight={450}
                    dollarValue={getCardAmount()}
                    personalMessage={input.personalMessage || DefaultPersonalMsg}
                    styles={Utils.parseStyle(card?.styles)}
                    ref={canvasRef}
                    showMessage={showMessage && formType === SOMEONE_ELSE}
                />
                <FormContainer
                    eGiftCard={eGiftCard}
                    promoConfig={promoConfig}
                    formType={formType}
                    setFormType={setFormType}
                    handleFormSubmit={handleFormSubmit}
                />
            </Row>
        </div>
    );
}

const mapState = ({ retailer, bag }: IReduxStore) => ({
    retailer,
    cards: bag.cards,
});

const mapDispatch = {
    setStatus,
    addCards,
    updateCard,
    openBag: () => toggleBagView(true),
    updatePromoCards,
};

export default connect(mapState, mapDispatch)(memo(CardPersonalize));
