import React, { useEffect, useRef, useCallback, useContext, useState } from 'react';
import '../../styles/main.scss';
import Header from '../../components/header/header';
import ContentView from './content';
import ShoppingBag from '../shopping-bag/shopping-bag';
import { Layout, Drawer } from 'antd';
import { notifyDiscardEdit } from '../../components/confirm/actions';
import { IRetailer, IShoppingBag, IReduxStore } from '../../components/common/types';
import {     API_BASE_URL, PAGEVIEWS, STATUS } from '../../components/common/constants';
import Service from '../../service/apollo-service';
import Utils from '../../components/common/utils';
import ReactGA from 'react-ga';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { setRetailer, setStatus, setCategory } from '../../store/container/actions';
import { toggleBagView } from '../../store/shopping-bag/actions';
import {  RouteComponentProps } from 'react-router-dom';
import FormWrapper from '../../components/form-wrapper/form-wrapper';
import { IFormProps } from '../../components/form-wrapper/types';
import { FormContext } from '../../components/form-wrapper/form-wrapper';
import { PaymentForm } from 'spoton-lib';

const { Content } = Layout;

interface IContainerProps extends RouteComponentProps {
    bag: IShoppingBag;
    retailer: IRetailer;
    firstCat: number;
    setStatus: (status: string) => void;
    setRetailer: (retailer: IRetailer) => void;
    closeBag: () => void;
    setCategory: (category: number) => void;
}

function Container({ retailer, bag, ...props }: IContainerProps) {
    const [redirectToEcard, setRedirectToEcard] = useState('');
    const { params, url }: any = props.match;
    const { pathname } = props.location;
    const form: IFormProps = useContext(FormContext);

    useEffect(() => {
        const checkGiftCardProvider = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/provider?merchantId=${params.merchantId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },

                });
                const responseStatus = await response.status;
                // Redirect to the ECard storefront if the response is 301
                // note: responding with a redirect from the server did not work as expected with fetch
                // so we reverted to responding with a status and the url and handle the redirection on the client
                if (responseStatus === 301) {
                    const responseData = await response.json();
                    setRedirectToEcard(responseData.url);
                }
            } catch (error) {
                setRedirectToEcard('');
                console.error('Fetch error:', error);
            }
        };

        checkGiftCardProvider();
    }, [params.merchantId]);

    if (redirectToEcard) {
        const { search } = window.location;
        window.location.href = redirectToEcard + search;
    }

    const isCheckoutPage = pathname.includes(PAGEVIEWS.CARDBILLING);
    const mqMobile = window.matchMedia('(max-width: 575px)');
    const paymentForm: React.RefObject<PaymentForm> = useRef(null);

    const isNewRetailer = () => {
        const { groupId, merchantId } = retailer;
        return groupId !== params.groupId || merchantId !== params.merchantId;
    };

    const getQueryParams = () => {
        const { search } = props.location;
        const values = queryString.parse(search);
        values.source = values.source ? values.source.toString() : '';
        const ser = new Service();
        return ser.getTrackingID().then((analytics) => {
            ReactGA.initialize(analytics ? analytics.trackingID : '');
            return values;
        });
    };

    /**
     * Initialize retailer with query params
     */
    const initialize = async () => {
        const { groupId, merchantId } = params;
        const values: any = await getQueryParams();
        const ser = new Service();
        const retailer = await ser.getRetailer(groupId, merchantId);
        if (retailer) {
            ReactGA.event({
                category: values.source,
                action: 'entry',
                label: merchantId,
            });
            retailer.queryParams = values;
            retailer.baseUrl = Utils.removeTrailSlash(url);
            props.setRetailer(retailer);
        } else {
            props.setStatus(STATUS.FAILED);
        }
    };

    useEffect(() => {
        if (isNewRetailer()) {
            props.setStatus(STATUS.LOADING);
            initialize();
        }
        if (retailer.retailerId) {
            window.removeEventListener('beforeunload', () => {
                ReactGA.event({
                    category: retailer.queryParams.source,
                    action: 'exit_close',
                    label: retailer.merchantId,
                });
            });
            if (retailer.customStyle) {
                Utils.addCustomStyle(retailer.customStyle);
            }
        }
    }, [retailer.retailerId]);

    const handleBackClick = useCallback(
        (returnUrl: string) => {
            props.closeBag();
            const { state }: any = props.location;
            if (state?.eGiftCard) {
                notifyDiscardEdit({
                    form,
                    retailer,
                    callback: () => props.history.push(returnUrl),
                });
            } else {
                props.history.push(returnUrl);
                if (pathname.includes(PAGEVIEWS.ORDERSUMMARY)) {
                    props.setCategory(props.firstCat);
                }
            }
        },
        [props.location, form.input]
    );

    return (
        <Layout className="layout">
            <Header handleBackClick={handleBackClick} />
            <Layout className="innerLayout">
                <Content>
                    <ContentView paymentForm={paymentForm} handleBackClick={handleBackClick} />
                </Content>
                <Drawer
                    className={
                        'bagContainer ' +
                        (bag.cards.length ? '' : 'bagEmpty') +
                        (isCheckoutPage && !mqMobile.matches ? 'belowHeader' : '')
                    }
                    visible={bag.visible}
                    closable={false}
                    onClose={() => props.closeBag()}
                >
                    <ShoppingBag
                        handlePayment={useCallback(() => {
                            paymentForm.current?.submitPaymentForm();
                        }, [paymentForm])}
                    />
                </Drawer>
            </Layout>
        </Layout>
    );
}

const mapState = (state: IReduxStore) => ({
    bag: state.bag,
    retailer: state.retailer,
    firstCat: state.cardDesigns[0]?.categoryId || -1,
});

const mapDispatch = {
    setStatus,
    setRetailer,
    closeBag: () => toggleBagView(false),
    setCategory,
};

export default connect(mapState, mapDispatch)(FormWrapper(Container));
