import { IGiftCard, IPromoConfig } from '../../components/common/types';

export enum BagAction {
    REMOVE_CARD = 'REMOVE_CARD',
    ADD_CARDS = 'ADD_CARDS',
    UPDATE_CARD = 'UPDATE_CARD',
    UPDATE_PROMO_CARDS = 'UPDATE_PROMO_CARDS',
    TOGGLE_BAG_VIEW = 'TOGGLE_BAG_VIEW',
    TOGGLE_EDIT_VIEW = 'TOGGLE_EDIT_VIEW',
    CLEAR_BAG = 'CLEAR_BAG',
}

interface IBagAction<T> {
    type: BagAction;
    payload: T;
}

type RemoveCardAction = IBagAction<number>;
type AddCardsAction = IBagAction<IGiftCard[]>;
type UpdateCardAction = IBagAction<IGiftCard>;
type UpdatePromoCardsAction = IBagAction<IPromoConfig>;
type ClearBagAction = IBagAction<null>;
type ToggleAction = IBagAction<boolean>;

export type BagActionTypes =
    | RemoveCardAction
    | AddCardsAction
    | UpdateCardAction
    | UpdatePromoCardsAction
    | ClearBagAction
    | ToggleAction;
