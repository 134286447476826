import { ICategory, IRetailer } from '../../components/common/types';

export enum ContentAction {
    SET_STATUS = 'SET_STATUS',
    SET_RETAILER = 'SET_RETAILER',
    SET_CARD_DESIGNS = 'SET_CARD_DESIGNS',
    SET_CATEGORY = 'SET_CATEGORY',
    SET_PAYABLE = 'SET_PAYABLE',
}

interface IContentAction<T> {
    type: ContentAction;
    payload: T;
}

type StatusAction = IContentAction<string>;
type RetailerAction = IContentAction<IRetailer>;
type CardDesignsAction = IContentAction<ICategory[]>;
type CategoryAction = IContentAction<number>;
type PayableAction = IContentAction<boolean>;

export type ContentActionTypes =
    | StatusAction
    | RetailerAction
    | CardDesignsAction
    | CategoryAction
    | PayableAction;
