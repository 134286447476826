import React from 'react';
import { Row, Col, Menu, List, Dropdown } from 'antd';
import { Text, TextTypes, IconButton } from 'spoton-lib';
import { IGiftCard, IReduxStore } from '../../../common/types';
import { STATUS } from '../../../common/constants';
import { connect } from 'react-redux';
import Utils from '../../../common/utils';
import DateUtils from '../../../common/date-utils';
import './bag-item.scss';

interface IBagItemProps {
    card: IGiftCard;
    editable: boolean;
    handleEdit: (card: IGiftCard) => void;
    handleRemove: (card: IGiftCard) => void;
}

function BagItem({ card, ...props }: IBagItemProps) {
    let { senderInfo, recipientInfo } = card;
    let sessionInfo = Utils.getSenderInfo();
    let customDate = DateUtils.getCustomDate(card.customDate, 'MMMM D, YYYY');
    let customTime = DateUtils.getCustomTime(card.customTime);

    const cardDetails = [
        {
            title: "Recipient's Email",
            description:
                senderInfo.shipToSelf === 'Y'
                    ? 'Email Delivered to You'
                    : recipientInfo.recipientEmail,
        },
        {
            title: "Sender's Name",
            description:
                senderInfo.shipToSelf === 'Y' ? sessionInfo.senderName : senderInfo.senderName,
        },
        {
            title: 'Deliver on',
            description: customDate + '\n' + customTime,
        },
    ];

    const renderDropdown = () => (
        <Menu className="options">
            <Menu.Item key="0" onClick={() => props.handleEdit(card)} className="menuItem">
                <Text type={TextTypes.P}>Edit</Text>
            </Menu.Item>
            {!Utils.isPromoCard(card.card) && (
                <Menu.Item key="1" onClick={() => props.handleRemove(card)} className="menuItem">
                    <Text type={TextTypes.P}>Delete</Text>
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <div className="bagItemDesktop dBlock dsmNone">
            <Row className="bagItemRow">
                <Col span={6}>
                    <img
                        src={card.cardImageUrl + Utils.getRandomString()}
                        className="cardImage"
                        alt="card design"
                    />
                </Col>
                <Col className="cardInfo" span={15}>
                    <Text type={TextTypes.SUB2} className="name">
                        {senderInfo.shipToSelf === 'Y'
                            ? sessionInfo.senderName
                            : recipientInfo.recipientName}
                    </Text>
                    <Text className="amount" type={TextTypes.P}>
                        {'USD ' + parseFloat(card.denomination).toFixed(2)}
                    </Text>
                </Col>
                <Col span={3}>
                    {props.editable && (
                        <Dropdown overlay={renderDropdown()} trigger={['click']}>
                            <IconButton
                                name="OptionsIcon"
                                alt="options"
                                size={24}
                                className="optionsIcon"
                                onClick={() => null}
                            />
                        </Dropdown>
                    )}
                </Col>
            </Row>
            <List
                className="cardDetails"
                itemLayout="horizontal"
                dataSource={cardDetails}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta title={item.title} description={item.description} />
                    </List.Item>
                )}
            />
        </div>
    );
}

const mapState = ({ bag, status }: IReduxStore) => ({
    cards: bag.cards,
    editable: status === STATUS.IDLE,
});

export default connect(mapState)(BagItem);
