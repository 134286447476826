import React, { useMemo } from 'react';
import './shopping-bag.scss';
import Utils from '../../components/common/utils';
import { Button, ButtonVariants } from 'spoton-lib';
import { removeGiftCard, removePromoCard } from '../../components/confirm/actions';
import { IGiftCard, IRetailer, IReduxStore } from '../../components/common/types';
import { PAGEVIEWS, STATUS } from '../../components/common/constants';
import { connect } from 'react-redux';
import { setStatus } from '../../store/container/actions';
import { toggleBagView, removeCard } from '../../store/shopping-bag/actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Service from '../../service/apollo-service';
import BagItemDesktop from '../../components/shopping-bag/bag-item/desktop/bag-item';
import BagItemMobile from '../../components/shopping-bag/bag-item/mobile/bag-item';
import BagFooter from '../../components/shopping-bag/bag-footer/bag-footer';
import BagHeader from '../../components/shopping-bag/bag-header/bag-header';

interface IShoppingBagProps extends RouteComponentProps {
    handlePayment: () => void;
    cards: IGiftCard[];
    editable: boolean;
    retailer: IRetailer;
    closeBagView: () => void;
    removeCard: (lineItemId: number) => void;
    setStatus: (status: string) => void;
}

function ShoppingBag({ cards, retailer, history, ...props }: IShoppingBagProps) {
    /**
     * Confirm removal of gift card
     * @param lineItemId card index (remove all if -1)
     */
    const confirmRemove = (lineItemId: number) => {
        removeGiftCard({
            lineItemId,
            callback: () => {
                const { state }: any = props.location;
                if (state?.eGiftCard) {
                    // Redirect to homepage from card personalization
                    if (
                        state.promoConfig ||
                        lineItemId === -1 ||
                        state.eGiftCard.lineitemid === lineItemId
                    ) {
                        history.push(retailer.baseUrl);
                    }
                }
            },
        });
    };

    /**
     * Remove gift card from shopping bag
     * Remove promo card if required
     * @param card gift card
     */
    const handleRemove = async (card: IGiftCard) => {
        const promoCards = Utils.getPromoCards(cards);
        if (promoCards.length) {
            const total = parseFloat(Utils.getTotalAmount(cards)) - parseFloat(card.denomination);
            console.log('Total:', total);
            props.setStatus(STATUS.LOADING);
            const promoConfig = await new Service().getPromoCard(
                retailer.groupId,
                retailer.merchantId,
                parseFloat(total.toPrecision(7))
            );
            props.setStatus(STATUS.IDLE);
            if (promoConfig) {
                removePromoCard({
                    allCards: cards,
                    promoConfig,
                    callback: () => {
                        props.removeCard(card.lineItemId);
                        history.push(retailer.baseUrl);
                    },
                });
            }
        } else {
            confirmRemove(card.lineItemId);
        }
    };

    const handleEdit = (card: IGiftCard) => {
        props.closeBagView();
        history.push({
            pathname: retailer.baseUrl + PAGEVIEWS.CARDPERSONALIZATION,
            state: { eGiftCard: card },
        });
    };

    // sort gift cards by lineitemid and cardtype
    // if bag is idle, filter by shiptoself
    const filtered = useMemo(() => {
        cards.sort((a, b) => {
            if (a.card.cardType !== b.card.cardType) {
                return Utils.isPromoCard(b.card) ? -1 : 1;
            }
            return a.lineItemId - b.lineItemId;
        });
        if (!props.editable) {
            return Utils.filterGiftCards(cards);
        }
        return cards;
    }, [cards, props.editable]);

    return (
        <React.Fragment>
            <BagHeader location={props.location} />
            <div className="cardWrapper">
                {filtered.map((card) => (
                    <BagItemMobile
                        key={card.lineItemId}
                        card={card}
                        handleEdit={() => handleEdit(card)}
                        handleRemove={() => handleRemove(card)}
                    />
                ))}
                {cards.map((card) => (
                    <BagItemDesktop
                        key={card.lineItemId}
                        card={card}
                        handleEdit={() => handleEdit(card)}
                        handleRemove={() => handleRemove(card)}
                    />
                ))}
                {cards.length > 1 && (
                    <Button
                        className="removeBtn dsmNone"
                        variant={ButtonVariants.TERTIARY}
                        onClick={() => confirmRemove(-1)}
                    >
                        {'Delete All'}
                    </Button>
                )}
                {props.editable && cards.length > 1 && (
                    <Button
                        className="removeBtn dNone dsmBlock"
                        variant={ButtonVariants.TERTIARY}
                        onClick={() => confirmRemove(-1)}
                    >
                        {'Delete All'}
                    </Button>
                )}
            </div>
            <BagFooter handlePayment={props.handlePayment} />
        </React.Fragment>
    );
}

const mapState = ({ bag, ...other }: IReduxStore) => ({
    cards: bag.cards,
    editable: bag.editable,
    retailer: other.retailer,
});

const mapDispatch = {
    closeBagView: () => toggleBagView(false),
    removeCard,
    setStatus,
};

export default connect(mapState, mapDispatch)(withRouter(ShoppingBag));
