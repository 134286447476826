import React from 'react';
import './404.css';
import { Button, Text, TextTypes } from 'spoton-lib';

const NoRouteFound = ({ history }: any) => {
    return (
        <div className="container404">
            <div>
                <h1 className="fourZeroFour">404</h1>
                <hr />
                <Text type={TextTypes.H2}>Oops! page not found</Text>
                <br />
                <Button onClick={() => history.goBack()} className="btnGoBack">
                    Go Back
                </Button>
            </div>
        </div>
    );
};

export default NoRouteFound;
