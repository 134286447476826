import React, { useImperativeHandle, useRef, useState, useEffect } from 'react';
import { Stage, Layer, Text, Image, Rect } from 'react-konva/lib/ReactKonvaCore';
import 'konva/lib/shapes/Rect';
import 'konva/lib/shapes/Text';
import 'konva/lib/shapes/Image';
import { DEFAULT_AMOUNT_TEXT_STYLE, DEFAULT_MESSAGE_TEXT_STYLE } from './constants';

const MARGIN = 16;
const LINEHEIGHT = 1.3; // default is 1, updated to 1.3 as characters overlaps in consecutive lines.
const DEFAULT_BACKGROUND = '#f7f7f7';
const DEFAULT_AMOUNT_TEXT = '$0.00';
const DEFAULT_MESSAGE = 'Your personal message goes here';

export interface IStyleConfig {
    font: string;
    fontSize: string;
    fontStyle: string;
    x: number;
    y: number;
    align: string;
    color: string;
    text?: string;
    type?: string;
    yBoxAlignment?: string;
    xBoxAlignment?: string;
    backgroundColor?: string;
}

interface ICardViewProps {
    className: string;
    width: number;
    height: number;
    baseImageURL: string;
    dollarValue: string;
    personalMessage: string;
    styles: Array<IStyleConfig>;
    containerHeight: number;
    showMessage: boolean;
}

export interface ICardViewRef {
    getDataURL(type: string): string;
}

let prevImageUrl = '';
const FONT_MARGIN = 16; // difference between amount and message text size

const CardView = React.forwardRef<ICardViewRef, ICardViewProps>(
    (props: ICardViewProps, ref: React.Ref<ICardViewRef>) => {
        const stageRef: React.Ref<any> = useRef(null);
        const [image, setImage]: any = useState();

        useEffect(() => {
            return () => {
                prevImageUrl = '';
            };
        }, []);

        useEffect(() => {
            const currentUrl = props.baseImageURL.split('?')[0];
            if (currentUrl !== prevImageUrl) {
                const newImage = new window.Image();
                newImage.src = props.baseImageURL;
                newImage.crossOrigin = 'Anonymous';
                newImage.onload = () => {
                    // wait for the transition to complete
                    setTimeout(() => setImage(newImage), 800);
                    prevImageUrl = props.baseImageURL.split('?')[0];
                };
            }
        }, [props.baseImageURL]);

        const getDataURL = (type: string): string => {
            return stageRef.current.toDataURL({ mimeType: type });
        };

        useImperativeHandle(ref, () => ({ getDataURL }));

        const getPointValue = (value: number, cardMargin: number) => {
            return value < 0 ? (cardMargin ? cardMargin : MARGIN) : value;
        };

        const getXOffet = (x: number, offset: number) => Math.abs(x) + offset;

        const widthOffset = props.width - getXOffet(getPointValue(-1, MARGIN), MARGIN);
        const xOffset = getPointValue(-1, MARGIN);

        const style = props.styles[0];

        const getFontSize = (isAmount=false) => {
            if (style?.fontSize && /^\+?(0|[1-9]\d*)$/.test(style?.fontSize)) {
                return isAmount ? parseInt(style?.fontSize, 10) + FONT_MARGIN : parseInt(style?.fontSize, 10);
            }
            return isAmount ? DEFAULT_AMOUNT_TEXT_STYLE.size : DEFAULT_MESSAGE_TEXT_STYLE.size;
        }

        return (
            <Stage
                width={props.width}
                height={props.containerHeight}
                ref={stageRef}
                className={props.className}
            >
                <Layer>
                    <Rect // to handle transparent images
                        width={props.width}
                        height={props.containerHeight}
                        fill={style?.backgroundColor || DEFAULT_BACKGROUND}
                    />
                    <Image image={image} width={props.width} height={props.height} />
                    <Text
                        text={
                            !props.dollarValue || props.dollarValue === '$'
                                ? DEFAULT_AMOUNT_TEXT
                                : props.dollarValue
                        }
                        fontStyle={'bold'}
                        fontSize={getFontSize(true)}
                        fontFamily={style?.font || DEFAULT_AMOUNT_TEXT_STYLE.font}
                        align={'center'}
                        x={xOffset}
                        y={getPointValue(
                            !props.showMessage ? props.height + 50 : props.height + 25,
                            MARGIN
                        )}
                        width={widthOffset}
                        fill={style?.color || DEFAULT_AMOUNT_TEXT_STYLE.color}
                    />
                    <Text
                        text={!props.showMessage ? '' : props.personalMessage || DEFAULT_MESSAGE}
                        fontStyle={'normal'}
                        fontSize={getFontSize()}
                        fontFamily={style?.font || DEFAULT_MESSAGE_TEXT_STYLE.font}
                        align={'center'}
                        x={xOffset}
                        y={getPointValue(props.height + 75, MARGIN)}
                        width={widthOffset}
                        fill={style?.color || DEFAULT_MESSAGE_TEXT_STYLE.color}
                        lineHeight={LINEHEIGHT}
                    />
                </Layer>
            </Stage>
        );
    }
);

export default CardView;
