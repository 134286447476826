import React, { useState, useEffect } from 'react';
import { Stage, Layer, Text, Image, Rect, Group } from 'react-konva/lib/ReactKonvaCore';
import 'konva/lib/shapes/Rect';
import 'konva/lib/shapes/Text';
import 'konva/lib/shapes/Image';
import { DEFAULT_BANNER_TEXT_STYLE, DEFAULT_BANNER_BACKGROUND, DEFAULT_MOBILE_BANNER_TEXT_SIZE } from '../common/constants';
import { useTextWidth } from '@imagemarker/use-text-width';
import _ from 'lodash';
import { IBanner } from '../common/types';
import Utils from '../common/utils';

interface IBannerProps {
    className: string;
    width: number;
    height: number;
    banner: IBanner | undefined;
}

export interface IBannerStyle {
    colors: {
        primary: string;
        accent: string;
        text: string;
    };
    font: {
        family: string;
        size: string;
    };
}

const DesktopConfig = {
    lineHeight: 8,
    textPadding: 14,
    alignment: {
        left: 20,
        right: 80,
        default: 293
    },
    defaultAlignment: 'default'
}

const MobileConfig = {
    lineHeight: 17,
    textPadding: 29,
    alignment: {
        left: 20,
        right: 80,
        default: 135
    },
    defaultAlignment: 'default'
}

function Banner({ banner, ...props }: IBannerProps) {
    const [image, setImage]: any = useState();

    const isDesktop = window.innerWidth > 575
    const config = isDesktop ? DesktopConfig : MobileConfig;
    const titleStyle = Utils.parseBannerStyle(banner?.titleStyle),
        subtitleStyle = Utils.parseBannerStyle(banner?.subtitleStyle),
        disclaimerStyle = Utils.parseBannerStyle(banner?.disclaimerStyle);

    const getDesktopFontSize = (key: string) => parseInt(eval(key)?.font?.size || DEFAULT_BANNER_TEXT_STYLE.size)

    const fontSize = {
        title: isDesktop ? getDesktopFontSize('titleStyle'): DEFAULT_MOBILE_BANNER_TEXT_SIZE.title,
        subtitle: isDesktop ? getDesktopFontSize('subtitleStyle') : DEFAULT_MOBILE_BANNER_TEXT_SIZE.subtitle ,
        disclaimer: isDesktop ? getDesktopFontSize('disclaimerStyle') : DEFAULT_MOBILE_BANNER_TEXT_SIZE.disclaimer
    }

    const title_width = useTextWidth({text: banner?.title, font: `${fontSize.title}px ${titleStyle?.font?.family}`}),
        subtitle_width = useTextWidth({text: banner?.subtitle, font: `${fontSize.subtitle}px ${subtitleStyle?.font?.family}`}),
        disclaimer_width = useTextWidth({text: banner?.title, font: `${fontSize.disclaimer}px ${disclaimerStyle?.font?.family}`});

    const group_align: any = config.defaultAlignment;

    const textHeight = {
        subtitle: config.lineHeight + (config.textPadding/2) + fontSize.title + fontSize.subtitle,
        disclaimer: (config.lineHeight * 2) + (config.textPadding/2) + fontSize.title + fontSize.subtitle + fontSize.disclaimer
    };

    const group_x_position = (
        group_align === "left" ? config.alignment.left :
            group_align === "right" ?
            (props.width - ( (_.max([title_width, subtitle_width, disclaimer_width]) || 0) + config.alignment.right) ) :
            group_align === "center" ?
            ((props.width/2) - ((_.max([title_width, subtitle_width, disclaimer_width]) || 0)/2)) : config.alignment.default
    );

    useEffect(() => {
        const newImage = new window.Image();
        const imageUrl = banner?.url;
        //This is a workaround for Chrome's cache causing issues on the banner. See DREG-652.
        const cacheBustParams = `?cachebust=${Math.round(new Date().getTime() / 1000)}`;
        newImage.src = imageUrl ? imageUrl + cacheBustParams : "";
        newImage.crossOrigin = 'Anonymous';
        newImage.onload = () => setImage(newImage);
    }, []);

    return (
            <Stage width={props.width} height={props.height} className={props.className}>
            <Layer>
                <Rect
                    width={props.width}
                    height={props.height}
                    fill={DEFAULT_BANNER_BACKGROUND}
                    cornerRadius={8}
                />
                <Image image={image} width={props.width} height={props.height} cornerRadius={8} />
                <Group x={group_x_position} >
                    <Text
                        text={banner?.title}
                        fontStyle={DEFAULT_BANNER_TEXT_STYLE.fontStyle}
                        fontSize={fontSize.title}
                        fontFamily={titleStyle?.font?.family}
                        padding={config.textPadding}
                        x={-config.textPadding}
                        verticalAlign='bottom'
                        fill={titleStyle?.colors?.text || DEFAULT_BANNER_TEXT_STYLE.color}
                    />
                    <Text
                        text={banner?.subtitle}
                        fontStyle={DEFAULT_BANNER_TEXT_STYLE.fontStyle}
                        fontSize={fontSize.subtitle}
                        verticalAlign='bottom'
                        fontFamily={subtitleStyle?.font?.family}
                        height={textHeight.subtitle}
                        fill={subtitleStyle?.colors?.text || DEFAULT_BANNER_TEXT_STYLE.color}
                    />
                    <Text
                        text={banner?.disclaimer}
                        fontSize={fontSize.disclaimer}
                        verticalAlign='bottom'
                        fontFamily={disclaimerStyle?.font?.family}
                        height={textHeight.disclaimer}
                        fill={disclaimerStyle?.colors?.text || DEFAULT_BANNER_TEXT_STYLE.color}
                    />
                </Group>
            </Layer>
        </Stage>
    );
}

export default Banner;
