import React, { useState, useEffect } from 'react';
import { Text, TextTypes, Button, ButtonVariants, Modal } from 'spoton-lib';
import './confirm.scss';

export var showConfirm: (options: IModalOptions) => void;

interface IModalOptions {
    title: string;
    body: string | JSX.Element;
    className?: string;
    confirmStyle?: string;
    confirmText: string;
    cancelText?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    hideCancel?: boolean;
}

function Confirm() {
    const [options, setOptions] = useState({} as IModalOptions);

    showConfirm = (options) => {
        setOptions(options);
    };

    useEffect(() => {
        if (options.title) {
            setTimeout(() => {
                // DOM method is used because modal component was not forwarding ref
                const modal: HTMLDivElement | null = document.querySelector('.confirm');
                if (modal) {
                    const style = window.getComputedStyle(modal);
                    const position = style.getPropertyValue('position');
                    if (position === 'fixed') {
                        modal.style.top = `calc(100% - ${modal.clientHeight}px)`;
                    }
                }
            }, 100);
        }
    }, [options]);

    const handleConfirm = () => {
        options.onConfirm();
        setOptions({} as IModalOptions);
    };

    const handleCancel = () => {
        if (typeof options.onCancel === 'function') {
            options.onCancel();
        }
        setOptions({} as IModalOptions);
    };

    // naming convention of some css classes may differ ...
    // ... as they are used to achieve backward compatibility
    return (
        <Modal
            isOpen={options.title !== undefined}
            onRequestClose={() => {
                !options.onCancel && setOptions({} as IModalOptions);
            }}
            className={'confirm ' + (options.className || '')}
        >
            <div className="w100 ant-modal-header" />
            <Text type={TextTypes.H4} className="title">
                {options.title}
            </Text>
            <div className="body">
                <Text type={TextTypes.P}> {options.body} </Text>
            </div>
            <div className="w100 modalFooter">
                {!options.hideCancel && (
                    <Button
                        variant={ButtonVariants.TERTIARY}
                        onClick={handleCancel}
                        className="cancelBtn"
                    >
                        {options.cancelText || 'Cancel'}
                    </Button>
                )}
                <Button onClick={handleConfirm} className={'confirmBtn ' + options.confirmStyle}>
                    {options.confirmText}
                </Button>
            </div>
        </Modal>
    );
}

export default Confirm;
