import React, { useContext, memo } from 'react';
import { Layout, Row } from 'antd';
import Utils from '../../common/utils';
import { Text, TextTypes, Button } from 'spoton-lib';
import { FormContext } from '../../form-wrapper/form-wrapper';
import { IGiftCard, IReduxStore } from '../../common/types';
import { PAGEVIEWS } from '../../common/constants';
import { connect } from 'react-redux';
import { toggleBagView } from '../../../store/shopping-bag/actions';
import { useHistory } from 'react-router-dom';
import './bag-footer.scss';

const { Footer } = Layout;

interface IBagFooterProps {
    baseUrl: string;
    handlePayment: () => void;
    cards: IGiftCard[];
    payable: boolean;
    closeBagView: () => void;
}

function BagFooter({ cards, ...props }: IBagFooterProps) {
    const history = useHistory();
    const { pathname } = history.location;
    const isCheckoutPage = pathname.includes(PAGEVIEWS.CARDBILLING);
    const mqMobile = window.matchMedia('(max-width: 575px)');
    const showPayButton = isCheckoutPage && !mqMobile.matches;
    const showBackButton = isCheckoutPage && mqMobile.matches;
    const { isFormValid } = useContext(FormContext);
    let buttonText = `Continue to Checkout`;

    if (showPayButton) {
        buttonText = 'Place Order';
    } else if (showBackButton) {
        buttonText = `Back to Checkout`;
    }

    if (cards.length) {
        let totalAmount = Utils.getTotalAmount(cards);
        return (
            <Footer className="bagFooter">
                <Row className="totalRow">
                    <Text type={TextTypes.SUB2} className="bagFooterText">
                        Total
                    </Text>
                    <Text className="bagFooterText dsmNone" type={TextTypes.SUB2}>
                        {'USD ' + totalAmount}
                    </Text>
                    <Text className="bagFooterText dNone dsmBlock" type={TextTypes.SUB2}>
                        {'$' + totalAmount}
                    </Text>
                </Row>
                <Button
                    disabled={showPayButton && (!props.payable || !isFormValid())}
                    onClick={() => {
                        if (showBackButton) {
                            props.closeBagView();
                        } else {
                            showPayButton
                                ? props.handlePayment()
                                : history.push(props.baseUrl + PAGEVIEWS.CARDBILLING);
                        }
                    }}
                >
                    {buttonText}
                </Button>
            </Footer>
        );
    }
    return (
        <Text type={TextTypes.SUB2} className="emptyBagText">
            {'Your cart is empty'}
        </Text>
    );
}

const mapState = ({ bag, retailer, payable }: IReduxStore) => ({
    cards: bag.cards,
    baseUrl: retailer.baseUrl,
    payable,
});

const mapDispatch = {
    closeBagView: () => toggleBagView(false),
};

export default connect(mapState, mapDispatch)(memo(BagFooter));
