import React from 'react';
import { Text, TextTypes } from 'spoton-lib';
import { Col } from 'antd';
import './order-card.scss';

interface TextGroupProps {
    label: string;
    value: string | JSX.Element;
    valueType?: TextTypes;
    span?: string | number;
}

const TextGroup = ({ label, value, valueType, ...rest }: TextGroupProps) => {
    return (
        <Col
            flex="auto"
            className={'textGroup' + (label === 'Message' ? ' message' : '')}
            {...rest}
        >
            <Text type={TextTypes.LABEL2}> {label} </Text>
            <Text type={valueType || TextTypes.P}> {value} </Text>
        </Col>
    );
};

export { TextGroup };
