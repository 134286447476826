import React, { useState, memo } from 'react';
import { Card, Button, ButtonVariants } from 'spoton-lib';
import './order-card.scss';
import { TextGroup } from './text-group';
import { IGiftCard } from '../common/types';
import DateUtils from '../common/date-utils';
import Utils from '../common/utils';

interface CardMobileProps {
    card: IGiftCard;
    senderEmail: string;
}

const CardMobile = ({ card, ...props }: CardMobileProps) => {
    const sender = card.senderInfo;
    const recipient = card.recipientInfo;
    const [expanded, setExpanded] = useState(false);
    const showDate = card.customDate || card.customTime;
    return (
        <Card className={'orderCard'}>
            <img
                alt="card image"
                src={card.cardImageUrl + Utils.getRandomString()}
                className="imageBox"
            />
            <TextGroup
                label="Gift Card Amount"
                value={'$' + parseFloat(card.denomination).toFixed(2)}
            />
            <TextGroup
                label="Custom Date"
                value={
                    showDate
                        ? `${DateUtils.getCustomDate(card.customDate)} | ${DateUtils.getCustomTime(
                              card.customTime
                          )}`
                        : 'ASAP'
                }
            />
            {expanded && (
                <>
                    <TextGroup
                        label="Recipient"
                        value={
                            <span className="multiline">
                                <span>{recipient.recipientName}</span>
                                <span>
                                    {sender.shipToSelf === 'Y'
                                        ? props.senderEmail
                                        : recipient.recipientEmail}
                                </span>
                            </span>
                        }
                    />
                    <TextGroup
                        label="Sender"
                        value={
                            <span className="multiline">
                                <span>{sender.senderName}</span>
                                <span>{props.senderEmail}</span>
                            </span>
                        }
                    />
                    {card.personalMessage && (
                        <TextGroup label="Message" value={card.personalMessage} />
                    )}
                </>
            )}
            <Button
                variant={ButtonVariants.TERTIARY}
                className="button3"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? 'View less info' : 'View more info'}
            </Button>
        </Card>
    );
};

export default memo(CardMobile);
