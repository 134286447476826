import { ICardDesign, IGiftCard, IRetailer, ICardSender, IPromoConfig } from '../common/types';
import { API_IMAGES_PATH, API_STYLES_PATH, DEFAULT_PRICE_TAGS } from '../common/constants';
import { IStyleConfig } from './card-view';
import _ from 'lodash';
import DateUtils from './date-utils';
import { IBannerStyle } from '../card-designs/banner';

class Utils {
    static parseStyle(style?: string): IStyleConfig[] {
        if (style && style.length) {
            return JSON.parse(style);
        }
        return [];
    }

    static removeTrailSlash(path: string): string {
        if (path && path !== '') {
            return path.replace(/\/+$/, '');
        }
        return path;
    }

    static tryDecode(uri: string): string {
        if (!_.isEmpty(uri)) {
            try {
                return decodeURI(uri);
            } catch (err) {
                console.log('error ', err);
            }
        }
        return '';
    }

    static getSelectedCard(card: ICardDesign, amount: string): IGiftCard {
        return {
            card: card,
            lineItemId: 0,
            denomination: amount,
            personalMessage: '',
            senderInfo: { senderName: '', senderEmail: '' },
            recipientInfo: {
                recipientName: '',
                recipientEmail: '',
            },
        };
    }

    static isPromoCard(card: ICardDesign): boolean {
        return card.cardType === 'P';
    }

    static getPromoCards(cards: IGiftCard[]): IGiftCard[] {
        return cards.filter((x) => this.isPromoCard(x.card));
    }

    static getGiftCards(cards: IGiftCard[]): IGiftCard[] {
        return cards.filter((x) => !this.isPromoCard(x.card));
    }

    static getCardIndex(index: string): string {
        const words = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh'];
        return words[parseInt(index) - 1] || '';
    }

    /**
     * Get retailer profile as an object
     * @param retailer retailer object
     */
    static getRetailerProfile(retailer: IRetailer): any {
        const profileArray = retailer.retailerProfiles || [];
        const profileObject: any = {};
        profileArray.forEach(({ profileName, profileValue }) => {
            profileObject[profileName] = profileValue;
        });
        return profileObject;
    }

    static getCardImageUrl(retailerId: number, imagename: string, url?: string): string {
        const custom_url = API_IMAGES_PATH + `${retailerId}/${imagename}?${Date.now()}`;
        return url ? url + `?${Date.now()}` : custom_url;
    }

    static getRandomString(): string {
        return '?' + Date.now();
    }

    /**
     * Calculate total amount (exclude promo cards)
     * @param cards gift cards
     */
    static getTotalAmount(cards: IGiftCard[]): string {
        let total = 0.0;
        cards.forEach((card) => {
            if (!this.isPromoCard(card.card)) {
                total += parseFloat(card.denomination);
            }
        });
        return total.toFixed(2);
    }

    /**
     * Get price tags from retailer profile
     * @param retailer
     */
    static getPriceTags(retailer: IRetailer): Array<{ label: string; value: number }> {
        const profile = this.getRetailerProfile(retailer);
        const { PriceTags } = profile;
        if (!_.isEmpty(PriceTags)) {
            return PriceTags.split(',')
            .sort((a: number, b: number) => a - b)
            .map((price: string) => ({
                label: '$' + price,
                value: parseFloat(price),
            }));
        }
        return DEFAULT_PRICE_TAGS;
    }

    /**
     * Create link tag to fetch custom style
     * @param filename
     */
    static addCustomStyle(filename: string): void {
        var head = document.getElementsByTagName('head')[0];
        var link: any = document.getElementById('retailer');
        if (link === null) {
            link = document.createElement('link');
            link.id = 'retailer';
            link.rel = 'stylesheet';
            link.type = 'text/css';
        }
        link.href = API_STYLES_PATH + filename;
        link.media = 'all';
        head.appendChild(link);
    }

    /**
     * Filter gift cards by sender info
     * @param cards gift cards
     */
    static filterGiftCards(cards: IGiftCard[]): IGiftCard[] {
        let quantity = 0;
        let images = '';
        let filtered = _.uniqBy(cards, (card) => {
            const { shipToSelf } = card.senderInfo;
            if (shipToSelf === 'Y') {
                quantity++;
                images += card.cardImageUrl + ';';
            }
            return shipToSelf === 'Y' || card.lineItemId;
        });
        return filtered.map((card) => {
            let { shipToSelf } = card.senderInfo;
            if (shipToSelf === 'Y') {
                return {
                    ...card,
                    quantity: quantity > 1 ? quantity : 0,
                    cardImageUrl: images.slice(0, -1), // remove last semicolon
                };
            }
            return { ...card, quantity: 0 };
        });
    }

    /**
     * Filter promo cards by promo config
     * @param cards gift cards
     * @param config promo config
     */
    static filterPromoCards(cards: IGiftCard[], config: IPromoConfig): IGiftCard[] {
        let promoCards = Utils.getPromoCards(cards);
        let giftCards = Utils.getGiftCards(cards);
        let { quantity, promoAmount } = config;
        if (quantity < promoCards.length) {
            promoCards = promoCards.slice(0, quantity);
        }
        promoCards.forEach((card) => {
            card.denomination = '' + promoAmount;
        });
        return [...giftCards, ...promoCards];
    }

    static setSenderInfo(senderInfo: ICardSender): void {
        sessionStorage.setItem('senderInfo', JSON.stringify(senderInfo));
    }

    static getSenderInfo(): ICardSender {
        return JSON.parse(sessionStorage.getItem('senderInfo') || '{}');
    }

    static clearStorage(key: string): void {
        sessionStorage.setItem(key, '');
    }

    static getLastItemId(cards: IGiftCard[]): number {
        return cards.map((x) => x.lineItemId).reduce((a, b) => (b > a ? b : a)) + 1;
    }

    static getCardObject(egc: IGiftCard): any {
        const { card, senderInfo, recipientInfo, customDate, customTime } = egc;
        return {
            card: {
                cardid: card.cardId,
                carddesc: card.description,
                cardimagename: card.imageName,
                carddisplayorder: card.displayOrder,
                styles: card.styles,
                type: card.cardType,
            },
            cardimage: egc.cardImage,
            lineitemid: egc.lineItemId,
            denomination: egc.denomination,
            personalmessage: egc.personalMessage,
            cardsenderinfo: {
                sendername: senderInfo.senderName,
                senderemail: senderInfo.senderEmail,
                shiptoself: senderInfo.shipToSelf,
            },
            cardrecipientinfo: {
                recipientname: recipientInfo.recipientName,
                recipientemail: recipientInfo.recipientEmail,
            },
            shipdate: DateUtils.mergeDateTime(customDate, customTime),
            merchantid: egc.merchantId,
        };
    }

    static parseBannerStyle(style?: string): IBannerStyle | undefined {
        if (style && style.length) {
            return JSON.parse(style);
        }
        return;
    }
}

export default Utils;
