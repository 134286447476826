import React from 'react';
import { Layout, Col } from 'antd';
import './header.scss';
import { POWERED_BY_LOGO, PAGEVIEWS } from '../common/constants';
import { connect } from 'react-redux';
import { toggleBagView } from '../../store/shopping-bag/actions';
import { IconButton, Button, ButtonVariants } from 'spoton-lib';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IReduxStore } from '../common/types';
import Utils from '../common/utils';

const { Header } = Layout;

interface IHeaderProps extends RouteComponentProps {
    retailerLogo: string;
    bagSize: number;
    bagVisible: boolean;
    toggleBagView: (collapsed: boolean) => void;
    handleBackClick: (url: string) => void;
}

const { PROMOCARDEDIT, CARDBILLING, CARDPERSONALIZATION } = PAGEVIEWS;

function HeaderView(props: IHeaderProps) {
    const { bagSize, bagVisible, toggleBagView } = props;
    const { pathname } = props.location;
    const mqMobile = window.matchMedia('(max-width: 575px)');
    let bagLabel = mqMobile.matches ? 'Cart' : '0';
    if (bagSize > 0) {
        bagLabel = mqMobile.matches ? `Cart (${bagSize})` : `${bagSize}`;
    }

    let showBagButton = false;
    // for desktop show bag on every page except order summary
    // for mobile show only on homepage
    if (props.match.isExact) {
        showBagButton = true;
    } else if (!mqMobile.matches) {
        if (pathname.includes(CARDPERSONALIZATION)) {
            showBagButton = true;
        }
    }

    return (
        <Header className="header dFlex">
            <Col className="logoContainer dsmNone">
                <div className="retailerLogo">
                    <img src={props.retailerLogo} />
                </div>
                <div className="poweredBy">
                    <img src={POWERED_BY_LOGO} alt="powered by" />
                </div>
            </Col>
            <Col className="heading dNone dsmFlex">
                <IconButton
                    size={20}
                    alt="back icon"
                    name="LeftIcon"
                    className="backIcon"
                    onClick={() => {
                        let url = props.match.url;
                        if (pathname.includes(PROMOCARDEDIT)) {
                            url = Utils.removeTrailSlash(url) + CARDBILLING;
                        }
                        props.handleBackClick(url);
                    }}
                    buttonProps={{
                        'aria-label': 'Back',
                    }}
                />
            </Col>
            {showBagButton && (
                <Col
                    onClick={() => toggleBagView(!bagVisible)}
                    className={'bagButton ' + (bagSize > 0 ? '' : 'emptyBagButton')}
                >
                    <div className="bagIcon" />
                    <Button
                        variant={ButtonVariants.TERTIARY}
                        onClick={() => null}
                        className="bagLabel"
                    >
                        <>{bagLabel}</>
                    </Button>
                </Col>
            )}
        </Header>
    );
}

const mapState = ({ bag, retailer }: IReduxStore) => {
    return {
        bagSize: bag.cards.length,
        retailerLogo: retailer.retailerLogo || '',
        bagVisible: bag.visible,
    };
};

const mapDispatch = { toggleBagView };

export default withRouter(connect(mapState, mapDispatch)(HeaderView));
