import moment from 'moment';
import _ from 'lodash';

class DateUtils {
    static isToday(date: Date): boolean {
        let today = new Date();
        return (
            today.getDate() === date.getDate() &&
            today.getMonth() === date.getMonth() &&
            today.getFullYear() === date.getFullYear()
        );
    }

    static getCustomDate(date?: string, format?: string): string {
        if (date) {
            if (this.isToday(new Date(date))) {
                return 'Today';
            } else {
                return moment(date).format(format || 'MM/DD/YYYY');
            }
        }
        return 'Today';
    }

    static getCustomTime(time?: string): string {
        return time ? moment(time).format('h:mm a') : 'ASAP';
    }

    static getLocalDate(date: Date, time: Date): Date {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes(),
            time.getSeconds()
        );
    }

    static getUTCTime(local: Date): number {
        return Date.UTC(
            local.getUTCFullYear(),
            local.getUTCMonth(),
            local.getUTCDate(),
            local.getUTCHours(),
            local.getUTCMinutes(),
            local.getUTCSeconds()
        );
    }

    /**
     * Merge date & time to get UTC value
     * @param dateString
     * @param timeString
     */
    static mergeDateTime(dateString?: string, timeString?: string): number {
        var date = new Date();
        var time = new Date();
        if (dateString) {
            date = new Date(dateString);
        }
        if (timeString) {
            time = new Date(timeString);
        } else if (dateString) {
            if (!this.isToday(date)) {
                time.setHours(0, 0, 0, 0);
            }
        }
        var local = this.getLocalDate(date, time);
        return this.getUTCTime(local);
    }

    static getTimeString(time: string): string {
        let temp = moment().format('MM/DD/YYYY') + ' ' + time;
        return moment(temp).format('MM/DD/YYYY hh:mm A');
    }
}

export default DateUtils;
