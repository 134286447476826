import React, { useState, useContext, memo } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'spoton-lib';
import './date-picker.scss';
import moment from 'moment';
import { FormContext } from '../../form-wrapper/form-wrapper';
import FormUtils from '../../common/form-utils';

function DatePicker() {
    const [focused, setFocused] = useState(false);
    const { input, valid, setFormState } = useContext(FormContext);

    const handleDateChange = (date: moment.Moment | null) => {
        setFormState('input', {
            customDate: date?.toString() || '',
        });
        const { customTime } = input;
        if (customTime) {
            let dateString = date ? date.toString() : '';
            let error = FormUtils.validateTime(dateString, customTime);
            if (error.length) {
                setFormState('valid', { customTime: false });
                setFormState('message', { customTime: error });
            } else if (!valid.customTime) {
                setFormState('valid', { customTime: true });
                setFormState('message', { customTime: '' });
            }
        }
    };

    return (
        <SingleDatePicker
            date={input.customDate ? moment(input.customDate) : null}
            onDateChange={handleDateChange}
            focused={focused}
            onFocusChange={({ focused }) => setFocused(focused)}
            id="date_picker"
            numberOfMonths={1}
            placeholder="MM/DD/YYYY"
            showClearDate={true}
            hideKeyboardShortcutsPanel={true}
            small={false}
        />
    );
}

export default memo(DatePicker);
