import React, { useEffect, useRef, memo } from 'react';
import { Text, TextTypes, Tabs, Button } from 'spoton-lib';
import './card-designs.scss';
import Service from '../../service/apollo-service';
import Utils from '../../components/common/utils';
import { API_IMAGES_PATH, PAGEVIEWS, STATUS } from '../../components/common/constants';
import { RouteComponentProps } from 'react-router-dom';
import {
    ICardDesign,
    ICategory,
    IRetailer,
    IReduxStore,
    IPromoConfig,
} from '../../components/common/types';
import { connect } from 'react-redux';
import { setCategory, setCardDesigns, setStatus } from '../../store/container/actions';
import PromoBanner from '../../components/card-designs/banner';

interface ICardDesignsProps extends RouteComponentProps {
    cardDesigns: ICategory[];
    category: number;
    retailer: IRetailer;
    bagSize: number;
    setCategory: (category: number) => void;
    setCardDesigns: (cards: ICategory[]) => void;
    setStatus: (status: string) => void;
}

interface ICardImageProps {
    src: string;
    onClick: (position: DOMRect) => void;
}

function CardImage(props: ICardImageProps) {
    const imageRef: React.Ref<HTMLImageElement> = useRef(null);

    const handleClick = () => {
        const cardRect = imageRef.current?.getBoundingClientRect() as DOMRect;
        const position = {
            top: cardRect.top,
            left: cardRect.left,
            width: cardRect.width,
            height: cardRect.height
        };
        props.onClick(position as DOMRect);
    };

    return (
        <div className="imageBox" onClick={handleClick}>
            <img className="cardImage" src={props.src} ref={imageRef} />
        </div>
    );
}

function CardDesigns(props: ICardDesignsProps) {
    const { cardDesigns, category, retailer } = props;
    const { state, pathname } = props.location as { state: IPromoConfig; pathname: string };

    useEffect(() => {
        if (retailer.retailerId && category === -1) {
            const ser = new Service();
            props.setStatus(STATUS.LOADING);
            ser.getCardDesigns(retailer.retailerId).then((cards) => {
                if (cards?.length) {
                    props.setCardDesigns(cards);
                } else {
                    props.setStatus(STATUS.FAILED);
                }
            });
        } else if (state?.cards) {
            props.setStatus(STATUS.LOADING);
            props.setCardDesigns([
                {
                    categoryId: -1,
                    description: '',
                    cards: state.cards,
                    displayOrder: 0,
                },
            ]);
        }
    }, [retailer.retailerId]);

    const renderCard = (card: ICardDesign) => {
        const path = Utils.removeTrailSlash(`${pathname}`) + PAGEVIEWS.CARDPERSONALIZATION;
        const stateObj: any = {};
        if (!state) {
            stateObj.eGiftCard = Utils.getSelectedCard(card, '');
        } else {
            stateObj.eGiftCard = Utils.getSelectedCard(card, '' + state.promoAmount);
            stateObj.promoConfig = { ...state, cards: [card] };
        }
        return (
            <CardImage
                key={card.cardId}
                src={Utils.getCardImageUrl(retailer.retailerId, card.imageName, card.url)}
                onClick={(position: DOMRect) => {
                    stateObj.eGiftCard.position = position;
                    props.history.push({
                        pathname: state ? path + '/1' : path,
                        state: stateObj
                    })
                }}
            />
        );
    };

    const { SelectGcText } = Utils.getRetailerProfile(retailer);
    const subTitle = SelectGcText || 'Select a gift card template';
    const showFooter = props.bagSize > 0 && !state;
    const promoImageUrl = (bannerName: string) =>
        API_IMAGES_PATH + retailer.retailerId + '/' + bannerName + Utils.getRandomString();
    const bannerWidth = (window.innerWidth * 0.9) < 1220 ? (window.innerWidth * 0.9) : 1220;

    const shouldDisplayCategories = cardDesigns.length > 1 && !state?.status;

    return (
        <div className={'cardDesignContainer ' + (showFooter ? 'hasFooter' : '')}>
            <Text type={TextTypes.H2} className="pageTitle mb16">
                E-Gift Cards
            </Text>
            {retailer?.bannerName && !retailer?.banner ?
                <img
                    className="banner"
                    alt="banner"
                    src={promoImageUrl(
                        window.innerWidth > 575
                            ? retailer.bannerName.split(';')[0].trim()
                            : (
                                  retailer.bannerName.split(';')[1] ||
                                  retailer.bannerName.split(';')[0]
                              ).trim()
                    )}
                /> :
                (retailer?.bannerName && retailer?.banner && <PromoBanner
                    width={bannerWidth}
                    height={100}
                    className="banner"
                    banner={window.innerWidth > 575 ? retailer.banner?.desktop : retailer.banner?.mobile}
                />)
            }
            <Text type={TextTypes.SUB1} className="pageSubTitle dsmNone">
                {state?.status ? 'Select a free e-gift card' : subTitle}
            </Text>
            {shouldDisplayCategories && (
                <Tabs
                    active={'' + category}
                    onSelect={(catid) => props.setCategory(parseInt(catid))}
                    className="categories"
                >
                    {cardDesigns.map((cat) => (
                        <Tabs.Tab
                            name={'' + cat.categoryId}
                            key={cat.categoryId}
                            className="category"
                        >
                            {cat.description}
                        </Tabs.Tab>
                    ))}
                </Tabs>
            )}
            <Text type={TextTypes.SUB2} className="pageSubTitle dNone dsmBlock">
                {!state ? 'Select a gift card template' : 'Select a free gift card'}
            </Text>
            <div className="cardDesigns">
                {state?.cards
                    ? state.cards.map(renderCard)
                    : cardDesigns.length > 0 &&
                        cardDesigns
                            .filter((cat) => cat.categoryId === category)[0]
                            .cards.map(renderCard)}
            </div>
            {showFooter && (
                <div className="footer dNone dsmBlock">
                    <Button
                        onClick={() => {
                            const { history, match } = props;
                            history.push(Utils.removeTrailSlash(match.url) + PAGEVIEWS.CARDBILLING);
                        }}
                    >
                        <>Continue to Checkout ({props.bagSize})</>
                    </Button>
                </div>
            )}
        </div>
    );
}

const mapState = ({ bag, ...other }: IReduxStore) => ({
    ...other,
    bagSize: bag.cards.length,
});

const mapDispatch = { setCategory, setCardDesigns, setStatus };

export default connect(mapState, mapDispatch)(memo(CardDesigns));
