import React, { Component, ComponentType, createContext } from 'react';
import { IAnyObject, IFormState, IFormProps } from './types';
import _ from 'lodash';

export const FormContext = createContext({} as IFormProps);

function FormWrapper(Container: ComponentType<any>) {
    return class FormWrapped extends Component<any, IFormState> {
        _options: { [key: string]: IAnyObject } = {};

        constructor(props: any) {
            super(props);
            this.state = {
                loading: false,
                initial: {}, // initial input
                input: {}, // input values
                valid: {}, // booleans
                message: {},
                required: {}, // booleans (true if undefined)
                validator: {}, // pure functions
                variant: {}, // pure functions
            };
        }

        /**
         * Map input options to state
         */
        componentDidUpdate() {
            if (this.state.loading) {
                const state: IAnyObject = {
                    initial: {},
                    valid: {},
                    message: {},
                    required: {},
                    validator: {},
                    variant: {},
                };
                for (let name in this._options) {
                    for (let option in this._options[name]) {
                        state[option] = state[option] || {};
                        state[option][name] = this._options[name][option];
                    }
                }
                state.input = { ...state.initial };
                state.loading = false;
                this._options = {};
                this.setState(state);
            }
        }

        /**
         * Get input type by input name
         * @param name input name
         */
        getInputType = (name: string) => {
            var lc = (value: string) => value.toLowerCase();
            if (lc(name).indexOf('email') > -1) {
                return 'email';
            }
            if (lc(name).indexOf('phone') > -1) {
                return 'number';
            }
            return 'name';
        };

        /**
         * Handle input change
         * @param name input name
         * @param value input value
         */
        handleChange = (name: string, value: any) => {
            const { variant } = this.state;
            if (typeof variant[name] === 'function') {
                value = variant[name](value, this.state);
            }
            this.validateInput(name, value);
        };

        /**
         * Check if input value is valid or not
         * @param name input name
         * @param value input value
         */
        isInputValid = (name: string, value: string) => {
            const { required, validator } = this.state;
            let isValid = true;
            if (value && value.trim()) {
                if (typeof validator[name] === 'function') {
                    let error = validator[name](value, this.state);
                    if (error?.length) {
                        isValid = false;
                    }
                }
            } else if (required[name] !== false) {
                isValid = false;
            }
            return isValid;
        };

        /**
         * Validate input value by input name
         * @param name input name
         * @param value input value
         */
        validateInput = (name: string, value: string) => {
            const { input, valid, message, validator, required } = _.cloneDeep(this.state);
            input[name] = value;
            valid[name] = true;
            message[name] = '';

            if (value && value.trim()) {
                if (typeof validator[name] === 'function') {
                    let error = validator[name](value, this.state);
                    if (error?.length) {
                        valid[name] = false;
                        message[name] = error;
                    }
                }
            } else if (required[name] !== false) {
                valid[name] = false;
                message[name] = 'Please input valid ' + this.getInputType(name);
            }

            this.setState({ input, valid, message });
        };

        /**
         * Check if form is valid to submit
         */
        isFormValid = () => {
            const { input, initial } = this.state;
            const isValid = Object.keys(input).every((key) => {
                return this.isInputValid(key, input[key]);
            });
            if (!_.isEmpty(initial)) {
                return isValid && !_.isEqual(initial, input);
            }
            return isValid;
        };

        /**
         * Get input props by input name
         * @param name input name
         */
        getInputProps = (name: string) => {
            const { input, valid, message, required } = this.state;
            return {
                name: name,
                value: input[name] || '',
                onChange: (e: any) => {
                    this.handleChange(name, e?.target ? e.target.value : e);
                },
                isValid: valid[name],
                errorMessage: message[name],
                required: required[name] !== false,
            };
        };

        /**
         * Get input wrapper with generic props
         * @param name input name
         * @param options input options
         */
        getInputWrapper = (name: string, options?: IAnyObject) => {
            if (this.state.loading) {
                this._options[name] = {
                    ...options,
                    initial: options?.initial || '',
                };
            }
            return (Input: React.ReactElement) => {
                const otherProps = this.getInputProps(name);
                return React.cloneElement(Input, {
                    ...otherProps,
                    ...Input.props,
                    className: 'customInput ' + (Input.props.className || ''),
                    onChange: Input.props.onChange || otherProps.onChange,
                });
            };
        };

        setFormState = (key: string, state: IAnyObject) => {
            if (_.isEmpty(state)) {
                this.setState({ [key]: {} });
            } else {
                this.setState({
                    [key]: { ...this.state[key], ...state },
                });
            }
        };

        render() {
            return (
                <FormContext.Provider
                    value={{
                        ...this.state,
                        isFormValid: this.isFormValid,
                        setFormState: this.setFormState,
                        decorate: this.getInputWrapper,
                        initialize: () => {
                            this.setState({ loading: true });
                        },
                    }}
                >
                    <Container {...this.props} />
                </FormContext.Provider>
            );
        }
    };
}

export default FormWrapper;
