import rootReducer from './container/reducer';
import { createStore, Store } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { IReduxStore } from '../components/common/types';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
    key: 'root',
    storage,
};

const persisted = persistReducer(persistConfig, rootReducer);

export const store: Store<IReduxStore> = createStore(
    persisted,
    // enable dev tools on local dev
    composeWithDevTools()
);

export const persistor = persistStore(store);
