import React, { RefObject, useContext, useEffect, memo } from 'react';
import { Text, TextTypes, PaymentForm, Input, Button, ButtonVariants } from 'spoton-lib';
import './checkout.scss';
import { PAGEVIEWS, STATUS } from '../../components/common/constants';
import Utils from '../../components/common/utils';
import FormUtils from '../../components/common/form-utils';
import Service from '../../service/apollo-service';
import { useScript } from '../../components/common/script-loader';
import { IRetailer, IGiftCard, IPromoConfig, IReduxStore } from '../../components/common/types';
import { connect } from 'react-redux';
import { setStatus, setPayable } from '../../store/container/actions';
import { IFormState, IFormProps } from '../../components/form-wrapper/types';
import { FormContext } from '../../components/form-wrapper/form-wrapper';
import { notifyPromoCards, notifyPaymentFailed } from '../../components/confirm/actions';
import { RouteComponentProps } from 'react-router-dom';
import { toggleBagView, clearBag } from '../../store/shopping-bag/actions';
import ReactGA from 'react-ga';

interface ICheckoutProps extends RouteComponentProps {
    backButton: (label: string) => JSX.Element;
    paymentForm: RefObject<PaymentForm>;
    scriptLoaded?: boolean;
    cards: IGiftCard[];
    retailer: IRetailer;
    payable: boolean;
    timestamp: string;
    setStatus: (status: string) => void;
    setPayable: (payable: boolean) => void;
    clearBag: () => void;
    toggleBag: (visible: boolean) => void;
}

const withScriptLoader = (Component: React.ComponentType<any>) => {
    return (props: ICheckoutProps) => {
        const [loaded, error] = useScript('https://secure.safewebservices.com/token/Collect.js', {
            'data-tokenization-key': props.retailer.tokenKey || '',
        });
        return <Component scriptLoaded={loaded} error={error} {...props} />;
    };
};

function Checkout(props: ICheckoutProps) {
    const form: IFormProps = useContext(FormContext);
    const { input, decorate, isFormValid } = form;
    const senderInfo = Utils.getSenderInfo();
    const mqMobile = window.matchMedia('(max-width: 575px)');

    /**
     * Call create order API to get order summary
     * @param token payment token
     */
    const proceedPayment = (token: string) => {
        const { paymentForm, retailer, cards, timestamp } = props;
        const { state } = paymentForm.current || {};
        const data = {
            transId: retailer.transId,
            paymentToken: token,
            orderTimestamp: timestamp,
            ccFirstName: input.firstname,
            ccLastName: input.lastname,
            ccTelephone: input.telephone,
            senderEmail: input.senderemail,
            postalCode: state?.postalCode || '',
            giftCards: cards.map((card) => Utils.getCardObject(card)),
        };
        data.giftCards.forEach((card: any) => {
            delete card.card['__typename'];
        });
        return new Service().createOrder(data);
    };

    /**
     * Use token to proceed payment
     * If successful show order summary else notify error
     * @param token payment token
     */
    const handlePaymentToken = (token: string) => {
        const { setStatus, history, retailer } = props;
        setStatus(STATUS.LOADING);
        proceedPayment(token).then((data) => {
            setStatus(STATUS.IDLE);
            if (data?.status) {
                ReactGA.event({
                    category: retailer.queryParams.source,
                    action: 'checkout_complete',
                    label: retailer.merchantId,
                });
                data.giftCards = [...props.cards];
                props.clearBag(); // clear bag state
                Utils.clearStorage('senderInfo');
                history.push({
                    pathname: retailer.baseUrl + PAGEVIEWS.ORDERSUMMARY,
                    state: { order: data },
                });
            } else {
                notifyPaymentFailed(data);
            }
        });
    };

    const handleSubmitting = (isSubmitting: boolean) => {
        console.log('Submitting...');
        props.setStatus(isSubmitting ? STATUS.LOADING : STATUS.IDLE);
    };

    const submitPaymentForm = () => {
        const { paymentForm } = props;
        paymentForm.current?.submitPaymentForm();
    };

    const handleIsValid = (isValid: boolean) => {
        console.log('is valid', isValid);
        props.setPayable(isValid);
    };

    /**
     * Continue to card selection page or card edit page
     * @param promoConfig promo config
     * @param alltome alltome flag
     */
    const handlePromoEdit = (promoConfig: IPromoConfig, allToMe: boolean) => {
        props.toggleBag(false);
        const { promoAmount, cards } = promoConfig;
        const { baseUrl } = props.retailer;
        if (cards.length === 1) {
            props.history.push({
                pathname: baseUrl + PAGEVIEWS.PROMOCARDEDIT + '/1',
                state: {
                    eGiftCard: Utils.getSelectedCard(cards[0], '' + promoAmount),
                    promoConfig: { ...promoConfig, allToMe },
                },
            });
        } else {
            props.history.push({
                pathname: baseUrl + '/promo',
                state: { ...promoConfig, allToMe },
            });
        }
    };

    /**
     * Check if promo cards are available
     */
    useEffect(() => {
        props.setPayable(false);
        const { retailer, cards, setStatus } = props;
        form.initialize();
        const total = Utils.getTotalAmount(cards);
        setStatus(STATUS.LOADING);
        new Service()
            .getPromoCard(retailer.groupId, retailer.merchantId, parseFloat(total))
            .then((config) => {
                setStatus(STATUS.IDLE);
                props.toggleBag(!mqMobile.matches);
                if (config?.status) {
                    config.originalQty = config.quantity;
                    config.quantity -= Utils.getPromoCards(cards).length;
                    if (config.quantity) {
                        notifyPromoCards({
                            promoConfig: config,
                            callback: handlePromoEdit,
                        });
                    }
                }
            });
    }, []);

    /**
     * Save sender info to session storage
     */
    useEffect(() => {
        let isEmailValid = FormUtils.validateEmail(input.senderemail) === '';
        isEmailValid = isEmailValid && input.senderemail === input.confirmemail;
        let isPhoneValid = FormUtils.validateUSNumber(input.telephone) === '';
        if (isEmailValid || isPhoneValid) {
            Utils.setSenderInfo({
                senderName: senderInfo.senderName || '',
                senderEmail: isEmailValid ? input.senderemail : '',
                telephone: isPhoneValid ? input.telephone : '',
            });
        }
    }, [input]);

    const preview = props.cards.slice(0, 3);

    return (
        <div className="checkoutContainer">
            <div className="cardPreview dNone dsmFlex">
                <div className="dFlex">
                    {preview.map((card, i) => (
                        <img
                            key={i}
                            src={card.cardImageUrl + Utils.getRandomString()}
                            alt="card image"
                            className="thumbnail"
                        />
                    ))}
                    <Text type={TextTypes.P}>Total ${Utils.getTotalAmount(props.cards)}</Text>
                </div>
                <Button
                    variant={ButtonVariants.TERTIARY}
                    onClick={() => props.toggleBag(true)}
                    className="button3"
                >
                    View Details
                </Button>
            </div>
            {props.backButton('Keep Shopping')}
            <br />
            <Text type={TextTypes.H4} className="pageTitle mb16">
                Checkout
            </Text>
            <div className="wrapper">
                <Text type={TextTypes.SUB1} className="inputTitle mb16">
                    Personal Details
                </Text>
                <div className="personalDetails">
                    {decorate('senderemail', {
                        initial: senderInfo.senderEmail,
                        validator: (value: string) => FormUtils.validateEmail(value),
                    })(<Input label="Sender's Email*" />)}

                    {decorate('confirmemail', {
                        initial: senderInfo.senderEmail,
                        validator: (value: string, state: IFormState) => {
                            const { senderemail } = state.input;
                            if (senderemail !== value) {
                                return 'Sender emails do not match. Please re-enter.';
                            }
                            return '';
                        },
                    })(<Input label="Confirm Sender's Email*" />)}

                    {decorate('telephone', {
                        initial: senderInfo.telephone,
                        variant: (value: string) => {
                            return FormUtils.validateTelephone(value);
                        },
                        validator: (value: string) => {
                            return FormUtils.validateUSNumber(value);
                        },
                    })(<Input label="Telephone Number*" />)}
                </div>
                <br />
                <Text type={TextTypes.SUB1} className="inputTitle mb8">
                    Payment Details
                </Text>
                <Text type={TextTypes.P} className="inputDesc">
                    You’ll receive a confirmation email with the order number.
                </Text>
                <div className="paymentDetails">
                    <div className="ccHolderName">
                        <div className="ccInputMargin dsmNone" />
                        {decorate('firstname')(<Input label="First Name*" />)}
                        {decorate('lastname')(<Input label="Last Name*" />)}
                    </div>
                    {props.scriptLoaded && (
                        <PaymentForm
                            ref={props.paymentForm}
                            paymentToken={handlePaymentToken}
                            isValid={handleIsValid}
                            isSubmitting={handleSubmitting}
                            cvvInputStyles={'cvv'}
                            ccExpiryInputStyles={'expiry'}
                            ccNumberInputStyles={'number'}
                            postalCodeInputStyles={'postalCode customInput'}
                            postalCodeLabel={'Zip Code*'}
                            v2={true}
                        />
                    )}
                </div>
            </div>
            <br />
            <div className="footer dNone dsmBlock">
                <Button
                    variant={ButtonVariants.PRIMARY}
                    onClick={submitPaymentForm}
                    disabled={!props.payable || !isFormValid()}
                >
                    <>Confirm & Pay</>
                </Button>
            </div>
        </div>
    );
}

const mapState = ({ bag, ...other }: IReduxStore) => ({
    retailer: other.retailer,
    cards: bag.cards,
    payable: other.payable,
    timestamp: bag.timestamp,
});

const mapDispatch = {
    setStatus,
    setPayable,
    clearBag,
    toggleBag: toggleBagView,
};

const CheckoutHOC = withScriptLoader(Checkout);

export default connect(mapState, mapDispatch)(memo(CheckoutHOC));
