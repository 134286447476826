import { ICategory, IRetailer } from '../../components/common/types';
import { ContentAction, ContentActionTypes } from './types';

const setRetailer = (retailer: IRetailer): ContentActionTypes => {
    return {
        type: ContentAction.SET_RETAILER,
        payload: retailer,
    };
};

const setStatus = (status: string): ContentActionTypes => {
    return {
        type: ContentAction.SET_STATUS,
        payload: status,
    };
};

const setCardDesigns = (cards: ICategory[]): ContentActionTypes => {
    return {
        type: ContentAction.SET_CARD_DESIGNS,
        payload: cards,
    };
};

const setCategory = (category: number): ContentActionTypes => {
    return {
        type: ContentAction.SET_CATEGORY,
        payload: category,
    };
};

const setPayable = (payable: boolean): ContentActionTypes => {
    return {
        type: ContentAction.SET_PAYABLE,
        payload: payable,
    };
};

export { setRetailer, setStatus, setCardDesigns, setCategory, setPayable };
