import { ContentAction, ContentActionTypes } from './types';
import bagReducer from '../shopping-bag/reducer';
import { BagActionTypes } from '../shopping-bag/types';
import { IReduxStore, ICategory, IRetailer } from '../../components/common/types';
import { STATUS } from '../../components/common/constants';

type ActionTypes = ContentActionTypes | BagActionTypes;

const INITIAL_STATE = {
    retailer: {} as IRetailer,
    status: STATUS.IDLE,
    cardDesigns: [],
    category: -1,
    payable: false, // valid to pay?
    bag: {
        cards: [],
        visible: false,
        editable: false,
        timestamp: Date.now().toString(),
    },
};

const idleReducer = (state: IReduxStore, action: ActionTypes): IReduxStore => {
    const { type, payload } = action;
    switch (type) {
        case ContentAction.SET_STATUS:
            return {
                ...state,
                status: payload as string,
            };
        case ContentAction.SET_CATEGORY:
            return {
                ...state,
                category: payload as number,
            };
        case ContentAction.SET_PAYABLE:
            return {
                ...state,
                payable: payload as boolean,
            };
        default:
            return {
                ...state,
                bag: bagReducer(state.bag, action),
            };
    }
};

const loadingReducer = (state: IReduxStore, action: ActionTypes): IReduxStore => {
    const { type, payload } = action;
    switch (type) {
        case ContentAction.SET_STATUS:
            return {
                ...state,
                status: payload as string,
            };
        case ContentAction.SET_RETAILER:
            return {
                ...state,
                retailer: payload as IRetailer,
                cardDesigns: [],
                category: -1,
                status: STATUS.IDLE,
                bag: {
                    ...state.bag,
                    cards: [],
                    visible: false,
                }
            };
        case ContentAction.SET_CARD_DESIGNS:
            const categories = payload as ICategory[];
            categories.sort((a, b) => a.displayOrder - b.displayOrder);
            return {
                ...state,
                cardDesigns: categories,
                category: categories[0].categoryId,
                status: STATUS.IDLE,
            };
        default:
            return state;
    }
};

const failedReducer = (state: IReduxStore, action: ActionTypes): IReduxStore => {
    const { type, payload } = action;
    switch (type) {
        case ContentAction.SET_STATUS:
            return {
                ...state,
                status: payload as string,
            };
        default:
            return state;
    }
};

const reducer = (state: IReduxStore = INITIAL_STATE, action: ActionTypes) => {
    // this reducer is used as a state machine
    // here status is used as a finite state
    switch (state.status) {
        case STATUS.IDLE:
            return idleReducer(state, action);
        case STATUS.LOADING:
            return loadingReducer(state, action);
        case STATUS.FAILED:
            return failedReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
