import { BagAction, BagActionTypes } from './types';
import { IGiftCard, IPromoConfig, IShoppingBag } from '../../components/common/types';
import { ContentActionTypes } from '../container/types';
import Utils from '../../components/common/utils';

type ActionTypes = ContentActionTypes | BagActionTypes;

const reducer = (state: IShoppingBag, action: ActionTypes): IShoppingBag => {
    const { type, payload } = action;
    const { cards, visible } = state;
    switch (type) {
        case BagAction.ADD_CARDS:
            return {
                ...state,
                cards: [...cards, ...(payload as IGiftCard[])],
                timestamp: Date.now().toString(),
            };
        case BagAction.UPDATE_CARD:
            return {
                ...state,
                cards: cards.map((card) => {
                    if (card.lineItemId === (payload as IGiftCard).lineItemId) {
                        return payload as IGiftCard;
                    }
                    return card;
                }),
                timestamp: Date.now().toString(),
            };
        case BagAction.UPDATE_PROMO_CARDS:
            return {
                ...state,
                cards: Utils.filterPromoCards(cards, payload as IPromoConfig),
                timestamp: Date.now().toString(),
            };
        case BagAction.TOGGLE_BAG_VIEW:
            return {
                ...state,
                visible: payload as boolean,
                editable: false,
            };
        case BagAction.TOGGLE_EDIT_VIEW:
            if (visible) {
                return {
                    ...state,
                    editable: payload as boolean,
                };
            }
            return state;
        case BagAction.REMOVE_CARD:
            return {
                ...state,
                cards:
                    (payload as number) > -1
                        ? cards.filter((card) => card.lineItemId !== payload)
                        : [],
                timestamp: Date.now().toString(),
            };
        case BagAction.CLEAR_BAG:
            return {
                ...state,
                cards: [],
                visible: false,
            };
        default:
            return state;
    }
};

export default reducer;
